import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Goods } from "types/orderType";

interface PaymentState {
  method: string;
  amount: number;
  discount: number;
  finalAmount: number;
  transactionId: string;
  isPaid: boolean;
  // couponUsed: false, // 쿠폰 사용 여부
  // couponValue: 0, // 쿠폰 가치
  couponList: Goods;
  pointsUsed: number;
  cardType: string;
  cardNumber: string;
}

const initialState: PaymentState = {
  method: "", // 결제 방법
  amount: 0, // 총 결제 금액
  discount: 0, // 할인 금액
  finalAmount: 0, // 최종 결제 금액
  transactionId: "", // 거래 ID
  isPaid: false, // 결제 완료 여부
  // couponUsed: false, // 쿠폰 사용 여부
  // couponValue: 0, // 쿠폰 가치
  couponList: {} as Goods,
  pointsUsed: 0, // 사용한 포인트
  cardType: "", // 카드 유형
  cardNumber: "", // 카드 번호 (암호화된 형태로 저장)
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    updatePayment: (state, action: PayloadAction<Partial<PaymentState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updatePayment } = paymentSlice.actions;

export default paymentSlice.reducer;
