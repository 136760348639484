import { styled } from "@mui/material/styles";
import { HanacardService } from "services/hanacardService";
import {
  Box,
  Tab,
  Tabs,
  TabsProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HANACARD_TAB_HEIGHT } from "const/layout";
import { useState } from "react";
import { HANA_FLIGHT_URL, HANA_HOTEL_URL } from "const/hanacard";

const enum HanacardTab {
  FLIGHT = "항공",
  HOTEL = "호텔 & 투어",
  LACHA = "모빌리티",
}

const tabLabels = [HanacardTab.FLIGHT, HanacardTab.HOTEL, HanacardTab.LACHA];

const enum TabBaseURL {
  FLIGHT = HANA_FLIGHT_URL,
  HOTEL = HANA_HOTEL_URL,
}

const getTabURL = (tab: HanacardTab, cno: string, ts: string) => {
  const params = `cno=${cno}&ts=${ts}&reqChannel=WEB`;

  switch (tab) {
    case HanacardTab.FLIGHT:
      return `${TabBaseURL.FLIGHT}?${params}`;
    case HanacardTab.HOTEL:
      return `${TabBaseURL.HOTEL}?${params}`;
    default:
      break;
  }
};

const MenuTab = () => {
  const theme = useTheme();
  const match = useMediaQuery("(min-width:321px)");

  const [isLoading, setIsLoading] = useState(false);
  const selectedTab = HanacardTab.LACHA;

  const navigateTo = async (tab: HanacardTab) => {
    if (tab === HanacardTab.LACHA) {
      return;
    }

    try {
      setIsLoading(true);
      const { cno, ts } = await HanacardService.getCnoAndTs();
      const tabURL = getTabURL(tab, cno, ts);
      window.location.href = `${tabURL}`;
    } catch (error) {
      alert("잠시 후 다시 시도해주세요.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledTabs
      value={selectedTab}
      variant={match ? "fullWidth" : "scrollable"}
      indicatorColor="primary"
      textColor="primary"
      sx={{
        position: "fixed",
        top: `calc(env(safe-area-inset-top) + 0px)`,
        height: `${HANACARD_TAB_HEIGHT}px`,
        mx: "24px",
        width: "calc(100% - 48px)",
        bgcolor: "#FFF",
        zIndex: 9999999,
      }}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    >
      {tabLabels.map((label, index) => (
        <Tab
          key={index}
          value={label}
          onClick={isLoading ? undefined : navigateTo.bind(null, label)}
          disableRipple
          label={
            <>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderBottom: "1px solid #D4DFE1",
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  pt: "14px",
                  pb: "9px",
                  wordBreak: "keep-all",
                  position: "relative",
                }}
              >
                {label}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderBottom:
                      label === selectedTab
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                />
              </Box>
            </>
          }
          sx={{
            fontSize: "18px",
            letterSpacing: "-0.95px",
            fontWeight: label === selectedTab ? "bold" : "normal",
            height: "40px",
            p: 0,
          }}
        />
      ))}
    </StyledTabs>
  );
};

export default MenuTab;

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <div className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});
