import { Stack } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";
import OrderStatusSection from "components/order/OrderStatusSection";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PaymentService from "services/paymentService";
import { OrderType } from "types/orderType";
import ProductInfoSection from "./history-detail-panel/sections/ProductInfoSection";
import { ProviderUtils } from "utils/providerUtils";
import RelatedProduct from "./history-detail-panel/sections/RelatedProduct";
import PaymentRefundInfoSection from "./history-detail-panel/sections/PaymentRefundInfoSection";

interface OrderMainContentProps {
  reservationDetails?: OrderType;
}

const OrderMainContent = ({
  reservationDetails: order,
}: OrderMainContentProps) => {
  const [reservationDetails, setReservationDetails] = useState<
    OrderType | undefined
  >(order);

  const [searchParams] = useSearchParams();
  const orderIdQuery = searchParams.get("orderId");
  const orderIdParam = useParams().orderId;
  const orderId = orderIdParam || orderIdQuery;
  const navigate = useNavigate();

  const isCancelled = reservationDetails?.orderStatus === "CANCELED";
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";

  // Fetch reservation details from server
  useEffect(() => {
    if (!reservationDetails) {
      if (orderId) {
        PaymentService.reserveDetail(orderId)
          .then((data) => {
            setReservationDetails(data);
          })
          .catch((error) => {
            console.error("Error fetching reservation details:", error);
            navigate(-1);
          });
      }
    }
  }, [navigate, orderId, reservationDetails]);

  return !reservationDetails ? (
    <LoadingSpinner />
  ) : (
    <Stack id={`image-to-be-saved-${orderId}`} gap={1.5}>
      <OrderStatusSection order={reservationDetails} />
      <ProductInfoSection order={reservationDetails} />
      {!ProviderUtils.isHanacard && (
        <RelatedProduct order={reservationDetails} />
      )}
      {isPurchased && (
        <PaymentRefundInfoSection type="payment" order={reservationDetails} />
      )}
      {isCancelled && reservationDetails.refundInfo && (
        <PaymentRefundInfoSection type="refund" order={reservationDetails} />
      )}
    </Stack>
  );
};

export default OrderMainContent;
