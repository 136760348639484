import { ReservationState } from "app/reservationSlice";
import { CostResponse } from "services/trainService";
import { Coupon } from "utils/reservationUtils";

export type PriceType = {
  originalPrice: number;
  discount: number;
  commission: number;
  paymentPrice: number;
};

// 총액 계산 함수
const calculateTotalPrice = ({
  csmrFare,
  mrkFare,
  sellDscntRt = 0,
  sellCmsnRt = 0,
  trnPsgTpCd,
}: Exclude<CostResponse, "buyDscntRt" | "trnPsrmClCd">): {
  originalPrice: number;
  expectedDiscount: number;
  expectedCommission: number;
  finalPrice: number;
} => {
  let finalPrice = 0;
  let originalPrice = trnPsgTpCd === "1" ? csmrFare : mrkFare;
  let expectedDiscount = 0;
  const expectedCommission = (originalPrice * sellCmsnRt) / 100;

  if (trnPsgTpCd === "1") {
    // 성인 가격은 csmrFare에서 sellDscntRt를 적용
    finalPrice = csmrFare * (1 - sellDscntRt / 100);
    expectedDiscount = csmrFare - finalPrice;
  } else if (trnPsgTpCd === "3") {
    // 어린이 가격은 mrkFare 기준
    finalPrice = mrkFare;
  }

  return { originalPrice, expectedDiscount, expectedCommission, finalPrice };
};

// 성인 가격은 csmrFare에서 sellDscntRt 를 적용한 금액, 어린이 가격은 mrkFare 으로 계산
export const calculateFilteredTotalPrice = (
  reservation: ReservationState
): PriceType => {
  const costDetailsArray = reservation.trainParams.costDetails;
  let totalOriginalPrice = 0;
  let totalExpectedDiscount = 0;
  let totalExpectedCommission = 0;
  let totalPayableAmount = 0;
  const adultsCount = reservation.searchParams.adults;
  const childrenCount = reservation.searchParams.children;

  costDetailsArray?.forEach((detail) => {
    if (detail.trnPsrmClCd === "1") {
      // 열차객실등급코드 일반
      const {
        originalPrice,
        expectedDiscount,
        expectedCommission,
        finalPrice,
      } = calculateTotalPrice(detail);

      if (detail.trnPsgTpCd === "1") {
        // 성인 대상 데이터
        totalOriginalPrice += originalPrice * adultsCount;
        totalExpectedDiscount += expectedDiscount * adultsCount;
        totalExpectedCommission += expectedCommission * adultsCount;
        totalPayableAmount += finalPrice * adultsCount;
      } else if (detail.trnPsgTpCd === "3") {
        // 어린이 대상 데이터
        totalOriginalPrice += originalPrice * childrenCount;
        totalExpectedDiscount += expectedDiscount * childrenCount;
        totalExpectedCommission += expectedCommission * childrenCount;
        totalPayableAmount += finalPrice * childrenCount;
      }
    }
  });

  return {
    originalPrice: totalOriginalPrice,
    discount: totalExpectedDiscount,
    commission: totalExpectedCommission,
    paymentPrice: totalPayableAmount + totalExpectedCommission,
  };
};

export const calculateNewPrice = (price: PriceType, coupon: Coupon) => {
  return {
    originalPrice: price.originalPrice + coupon.saleAmount,
    discount: price.discount,
    commission: price.commission,
    paymentPrice: price.paymentPrice + coupon.saleAmount,
  };
};
