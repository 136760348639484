import { Stack, Typography } from "@mui/material";
import useFormatPrice from "hooks/useFormatPrice";
import { forwardRef } from "react";
import GMELogo from "assets/images/logo/gme_logo.png";
import { FormattedMessage } from "react-intl";
import CustomButton from "components/button/CustomButton";
import useTranslateStation from "hooks/useTranslateStation";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import Arrow from "components/Arrow";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { OrderType } from "types/orderType";
import { GMEPaymentRequestResponse } from "services/paymentService";
import { getMobileOperatingSystem } from "utils/osUtils";

interface GMEBottomSheetContentProps {
  value: GMEPaymentRequestResponse;
  resetBottomSheet: () => void;
  reservationDetails: OrderType;
}

const GMEBottomSheetContent = forwardRef<
  HTMLDivElement,
  GMEBottomSheetContentProps
>(({ value, resetBottomSheet, reservationDetails }, ref) => {
  const { availablePay, key } = value;

  const handleClick = () => {
    const os = getMobileOperatingSystem();

    if (os === "Android") {
      if (availablePay) {
        Android.needtocertification(key);
      } else {
        Android.gotodepositapplyguide("deposit insufficient");
      }
    } else {
      if (availablePay) {
        window.webkit.messageHandlers.iosWebView.postMessage({
          childData: "needtocertification",
          value: key,
        });
      } else {
        window.webkit.messageHandlers.iosWebView.postMessage({
          childData: "gotodepositapplyguide",
          value: "deposit insufficient",
        });
      }
    }
  };

  return (
    <Stack
      ref={ref}
      justifyContent="space-between"
      gap={1}
      sx={{ width: "100%", height: "100%", p: 2, overflow: "auto" }}
    >
      {availablePay ? (
        <SufficientContent reservationDetails={reservationDetails} />
      ) : (
        <InsufficientContent />
      )}

      <Stack gap={1} sx={{ width: "100%" }}>
        {availablePay ? (
          <Typography variant="caption" color="text.secondary" sx={{ mx: 2 }}>
            * Insufficient funds will be auto-deducted from your linked bank
            account.
          </Typography>
        ) : (
          <Typography
            variant="h6"
            color="error"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Insufficient balance!
          </Typography>
        )}
        <CustomButton size="medium" color="primary" onClick={handleClick}>
          {availablePay ? "Pay" : "Recharge"}
        </CustomButton>
        <CustomButton
          size="medium"
          variant="outlined"
          color="primary"
          onClick={resetBottomSheet}
        >
          Cancel
        </CustomButton>
      </Stack>
    </Stack>
  );
});

export default GMEBottomSheetContent;

interface SufficientContentProps {
  reservationDetails: OrderType;
}

const SufficientContent = ({ reservationDetails }: SufficientContentProps) => {
  const formatPrice = useFormatPrice();
  const translateStationName = useTranslateStation();
  const formatDateTime = useFormatDateToLocaleString();

  const TravelInfo = ({ status }: { status: "departure" | "arrival" }) => {
    return (
      <Stack component="div" alignItems="center" gap={0.5}>
        <Typography variant="h5" color="primary" sx={{ fontWeight: "bold" }}>
          {translateStationName(
            status === "departure"
              ? reservationDetails?.trainList[0].startStationName
              : reservationDetails?.trainList[0].destStationName
          )}
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          {status === "departure" ? "Date" : "Depart"}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {status === "departure"
            ? formatDateTime(reservationDetails?.startDate, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : formatDateTime(reservationDetails?.startDate, {
                hour: "2-digit",
                minute: "2-digit",
              })}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: 2, width: "100%", height: "100%" }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}
        gap={4}
      >
        <img src={GMELogo} alt="GME Logo" height="36px" />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ minWidth: "75vw" }}
        >
          <TravelInfo status="departure" />
          <Arrow variant="large" />
          <TravelInfo status="arrival" />
        </Stack>
        <Stack
          gap={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "95%" }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="payment.totalPrice" />
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {formatPrice(
              reservationDetails?.totalPrice -
                reservationDetails?.pointUsed -
                reservationDetails?.externalPointUsed
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

const InsufficientContent = () => {
  return (
    <Stack justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
      <ErrorOutlineIcon
        color="error"
        fontSize="large"
        sx={{ fontSize: "10rem" }}
      />
    </Stack>
  );
};
