import { useEffect, useState } from "react";

const useIsScrolling = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolling(true);
    });
    window.addEventListener("scrollend", () => {
      setIsScrolling(false);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setIsScrolling(true);
      });
      window.removeEventListener("scrollend", () => {
        setIsScrolling(false);
      });
    };
  }, []);

  return isScrolling;
};

export default useIsScrolling;
