import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import UserService from "services/userService";
import { calculateDateBeforeOneMonth } from "utils/dateUtils";
import { OrderType } from "types/orderType";
import Reservation from "./Reservation";
import { HANACARD_TAB_HEIGHT } from "const/layout";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import MenuTab from "./MenuTab";
import SubTab from "./SubTab";

const MyReservations = () => {
  const navigate = useNavigate();

  const showPaymentPage = (orderId: number) => {
    navigate(`/hanacard/order-details/${orderId}`, {
      state: { hanacard: true },
    });
  };

  return (
    <Stack
      sx={{
        "*": {
          letterSpacing: "-0.05em",
        },
        bgcolor: "#FFF",
      }}
    >
      <MenuTab />
      <SubTab />
      <ReservationContent showPaymentPage={showPaymentPage} />
    </Stack>
  );
};

export default MyReservations;

interface ReservationContentProps {
  showPaymentPage: (orderId: number) => void;
}

const ReservationContent = ({ showPaymentPage }: ReservationContentProps) => {
  const [reservations, setReservations] = useState<OrderType[]>();

  useEffect(() => {
    const getReservations = async () => {
      const response = await UserService.getMyOrders(
        calculateDateBeforeOneMonth()
      );

      const currentDate = new Date();

      const validReservations = response.content
        .filter((reservation) => reservation.orderStatus !== "CANCELED")
        .filter(
          (reservations) => new Date(reservations.endDate) >= currentDate
        );

      setReservations(validReservations);
    };
    try {
      getReservations();
    } catch (error) {
      console.error("예약 현황 가져오는데 실패.", error);
    } finally {
    }
  }, []);

  return (
    <Stack
      gap={2}
      sx={{
        mt: `calc(env(safe-area-inset-top) + ${HANACARD_TAB_HEIGHT * 2}px)`,
        p: 3,
        bgcolor: "#FFF",
        minHeight: `calc(100vh - env(safe-area-inset-top) - ${
          HANACARD_TAB_HEIGHT * 2
        }px)`,
      }}
    >
      <Stack direction="row" gap={0.5} alignItems="end">
        <h3 style={{ fontWeight: "bold" }}>예약 현황</h3>
        <Typography color="primary" sx={{ fontWeight: "bold" }}>
          ({reservations?.length ?? 0}건)
        </Typography>
      </Stack>
      <Stack gap={1}>
        {reservations ? (
          reservations?.map((reservation, index) => (
            <Reservation
              key={index}
              orderId={reservation.orderId}
              showPaymentPage={showPaymentPage}
            />
          ))
        ) : (
          <LoadingSpinner overlap />
        )}
      </Stack>
    </Stack>
  );
};
