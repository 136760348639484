import { FormattedMessage, useIntl } from "react-intl";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import styled from "styled-components";
import { CostResponse } from "services/trainService";
import DiscountIcon from "@mui/icons-material/Discount";
import SouthIcon from "@mui/icons-material/South";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";

interface CostDetailsProps {
  adultPriceDetail?: CostResponse;
  childPriceDetail?: CostResponse;
  isDiscount: boolean;
}

const CostDetailsSection = ({
  adultPriceDetail,
  childPriceDetail,
  isDiscount,
}: CostDetailsProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const theme = useTheme();
  const formatPrice = useFormatPrice();

  const [showInfo, setShowInfo] = useState(false);

  // data
  // const adultPriceDetail = costDetails?.find(
  //   (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  // );
  // const childPriceDetail = costDetails?.find(
  //   (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "3"
  // );
  // const isDiscount = Boolean(adultPriceDetail?.sellDscntRt);

  const toggleInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const hideInfo = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    window.addEventListener("click", hideInfo);

    return () => {
      window.removeEventListener("click", hideInfo);
    };
  }, []);

  return (
    <MediaQueryWrapper>
      {ProviderUtils.discountAvailable && isDiscount && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{ position: "relative" }}
          >
            <Typography
              variant="body2"
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
                borderRadius: theme.shape.borderRadius,
                px: 1,
                py: 0.5,
                mb: 0.5,
                fontWeight: "bold",
              }}
            >
              {intl.formatMessage(
                { id: "fareInquiry.upTo20PercentOff" },
                { rate: adultPriceDetail?.sellDscntRt }
              )}
            </Typography>

            <InfoIcon
              fontSize="small"
              color="disabled"
              onClick={toggleInfo}
              sx={{ position: "absolute", right: -22, bottom: 4 }}
            />
          </Stack>
          {showInfo && (
            <Tooltip title="purchase information" arrow>
              <CardLayout
                sx={{
                  p: 1,
                  position: "absolute",
                  top: 32,
                  maxWidth: "80%",
                  zIndex: 10,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    lineHeight: 1.3,
                    textAlign: "center",
                    wordBreak: "keep-all",
                  }}
                >
                  <FormattedMessage
                    id="train.costDetailedInformation"
                    defaultMessage={intl.formatMessage({
                      id: "This train ticket is part of a Korail travel package and can only be used when purchased in conjunction with a Kakao T coupon",
                    })}
                  />
                </Typography>
              </CardLayout>
            </Tooltip>
          )}

          {/* 정상가 기준  */}
          <Stack direction="row" gap={0.3} alignItems="center">
            <DiscountIcon sx={{ fontSize: "12px" }} />
            <Typography
              component="div"
              sx={{ fontSize: "12px", lineHeight: 1.2 }}
            >
              {intl.formatMessage({ id: "fareInquiry.standardPrice" })}
            </Typography>
          </Stack>
        </>
      )}

      {/* 기차 가격 표시 */}
      <Stack
        direction="row"
        gap={0.5}
        style={{
          color: theme.palette.mode === "light" ? grey[700] : grey[300],
        }}
      >
        <Typography component="span" variant="body2">
          {
            intl
              .formatMessage(
                { id: "fareInquiry.adultPrice" },
                { price: formatPrice(adultPriceDetail?.csmrFare) }
              )
              .split(":")[0]
          }
          :{" "}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ position: "relative" }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{
              textDecoration:
                ProviderUtils.discountAvailable && isDiscount
                  ? "line-through"
                  : "none",
              textDecorationColor: theme.palette.error.main,
            }}
          >
            {
              intl
                .formatMessage(
                  { id: "fareInquiry.adultPrice" },
                  { price: formatPrice(adultPriceDetail?.csmrFare) }
                )
                .split(":")[1]
            }
          </Typography>
          {ProviderUtils.discountAvailable && isDiscount && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                position: "absolute",
                transform: "translateX(8px)",
                left: "calc(100%)",
              }}
            >
              <Typography
                component="div"
                variant="body2"
                sx={{
                  color: theme.palette.error.main,
                  display: "flex",
                  alignItems: "center",
                  width: "max-content",
                }}
              >
                {adultPriceDetail?.sellDscntRt}%
                {ProviderUtils.isGME ? (
                  isKorean ? (
                    " 할인"
                  ) : (
                    " off"
                  )
                ) : (
                  <SouthIcon
                    fontSize="small"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: theme.palette.error.main,
                    }}
                  />
                )}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Typography
        component="span"
        variant="body2"
        style={{
          color: theme.palette.mode === "light" ? grey[700] : grey[300],
        }}
      >
        {intl.formatMessage(
          { id: "fareInquiry.childPrice" },
          { price: formatPrice(childPriceDetail?.mrkFare) }
        )}
      </Typography>
    </MediaQueryWrapper>
  );
};

export default CostDetailsSection;

const MediaQueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 512px) {
    align-items: center;
  }
`;
