import { Box, Stack, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import useOrderStatusText from "hooks/useOrderStatusText";
import { useEffect, useState } from "react";
import { OrderType } from "types/orderType";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PaymentService from "services/paymentService";
import { FormattedMessage } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import ReservationDetails from "components/reservation/ReservationDetails";
import CustomButton from "components/button/CustomButton";
import TicketPreview from "components/reservation/TicketPreview";
import Ticket from "components/reservation/Ticket";
import CloseIcon from "@mui/icons-material/Close";
import useCancelReservation from "hooks/useCancelReservation";
import PaymentRefundInfoSection from "pages/ktx/order-history-panel/history-detail-panel/sections/PaymentRefundInfoSection";
import LoadingSpinner from "components/LoadingSpinner";

interface ReservationProps {
  orderId: number;
  showPaymentPage: (orderId: number) => void;
}

const Reservation = ({ orderId, showPaymentPage }: ReservationProps) => {
  const formatDateTime = useFormatDateToLocaleString();
  // 주문 상태 코드를 텍스트로 변환하는 함수
  const getOrderStatusText = useOrderStatusText();

  const [reservationDetails, setReservationDetails] =
    useState<OrderType | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [isTicket, setIsTicket] = useState(false);

  useEffect(() => {
    if (orderId) {
      PaymentService.reserveDetail(orderId)
        .then((data) => {
          setReservationDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching reservation details:", error);
        });
    }
  }, [orderId]);

  const toggleDetails = () => {
    setIsTicket(false);
    setExpanded(!expanded);
  };
  const showTicket = () => {
    setExpanded(true);
    setIsTicket(true);
  };

  const closeTicket = () => {
    setExpanded(true);
    setIsTicket(false);
  };

  return reservationDetails ? (
    <CardLayout sx={{ border: "none" }}>
      <Stack alignItems="start" gap={0.5} sx={{ width: "100%" }}>
        <Box
          sx={{
            bgcolor: "homeBanner.bgColor",
            borderRadius: "6px",
          }}
        >
          <Typography
            variant="caption"
            color="primary"
            sx={{ py: 0, px: 1, fontWeight: "bold" }}
          >
            {getOrderStatusText(reservationDetails?.orderStatus)}
          </Typography>
        </Box>
        <Stack direction="row" gap={1}>
          <Typography variant="caption" color="text.secondary">
            {formatDateTime(reservationDetails?.orderCreatedDate, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              weekday: "short",
            }).replaceAll(" ", "")}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {orderId}
          </Typography>
        </Stack>
        <TicketPreview reservationDetails={reservationDetails} />

        <Stack gap={1.5} sx={{ width: "100%" }}>
          {expanded &&
            reservationDetails &&
            (isTicket ? (
              <Stack
                alignItems="end"
                sx={{ width: "100%", position: "relative" }}
              >
                <CloseIcon
                  onClick={closeTicket}
                  sx={{
                    position: "absolute",
                    color: "#FFF",
                    zIndex: 10,
                    top: 8,
                    right: 8,
                  }}
                />
                <Ticket reservationDetails={reservationDetails} />
              </Stack>
            ) : (
              <ReservationDetail reservationDetails={reservationDetails} />
            ))}

          {/* 자세히보기 버튼 */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignSelf="end"
            onClick={toggleDetails}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ lineHeight: 1.2 }}
            >
              자세히보기
            </Typography>
            {expanded ? (
              <KeyboardArrowUpIcon sx={{ fontSize: "16px" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ fontSize: "16px" }} />
            )}
          </Stack>

          {/* 결제하기 / 승차권 보기 버튼 */}
          {!isTicket && reservationDetails.orderStatus === "COMPLETE" && (
            <CustomButton variant="outlined" size="medium" onClick={showTicket}>
              <FormattedMessage id="orders.showTrainTicket" />
            </CustomButton>
          )}
          {reservationDetails.orderStatus === "PENDING" && (
            <CustomButton
              variant="outlined"
              size="medium"
              onClick={() => showPaymentPage(orderId)}
            >
              결제하기
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </CardLayout>
  ) : (
    <LoadingSpinner />
  );
};

export default Reservation;

const ReservationDetail = ({
  reservationDetails,
}: {
  reservationDetails: OrderType;
}) => {
  const formatPrice = useFormatPrice();

  // cancel reservation
  const [CancelDetailsComponent] = useCancelReservation(reservationDetails);

  const getAdditionalInfo = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="orders.price" />:
          </Typography>

          <Typography
            variant="h6"
            component="div"
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            {formatPrice(
              Number(reservationDetails.totalPrice) -
                reservationDetails.goodsList.price
            )}
          </Typography>
        </Stack>
        {reservationDetails.orderStatus === "COMPLETE" && (
          <>
            <PaymentRefundInfoSection
              type="payment"
              order={reservationDetails}
              boxShadow={false}
            />
            {/* 결제 완료한 경우 취소 예상 금액 보여주기 */}
            {CancelDetailsComponent}
          </>
        )}
      </>
    );
  };

  return (
    <CardLayout
      additionalInfo={getAdditionalInfo()}
      sx={{
        border: "none",
        boxShadow: "none",
        bgcolor: "secondary.light",
        width: "100%",
        p: 1.5,
      }}
    >
      <ReservationDetails reservationDetails={reservationDetails} />
    </CardLayout>
  );
};
