import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

interface PointParkIframeProps {
  pointParkUrl: string;
  onClose: () => void;
}

const PointParkIframe = ({ pointParkUrl, onClose }: PointParkIframeProps) => {
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  });

  return (
    <div id="point-park-iframe">
      <CloseIcon
        onClick={handleClose}
        fontSize="large"
        sx={{
          display: pointParkUrl ? "block" : "none",
          position: "fixed",
          top: 16,
          right: 24,
          cursor: "pointer",
          zIndex: 100000,
        }}
      />
      <iframe
        title="Point Park"
        src={pointParkUrl}
        style={{
          display: pointParkUrl ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#FFF",
          zIndex: 10000,
        }}
      />
    </div>
  );
};

export default PointParkIframe;
