import { NavigateFunction, useNavigate } from "react-router-dom";

const useContinueSearching = () => {
  const navigate: NavigateFunction = useNavigate();

  return () => {
    navigate("/", { state: { isSearching: true }, replace: true });
  };
};

export default useContinueSearching;
