import React, { useEffect, useRef, useState } from "react";
import { Container, Stack, Divider, useTheme, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import TrainService from "services/trainService";
import {
  BookerInfo,
  Coupon,
  ReservationInput,
  createReservationData,
} from "utils/reservationUtils";
import PassengerInfoSection, {
  PassengerDetailField,
} from "./sections/PassengerInfoSection";
import CouponSection from "./sections/CouponSection";
import TravelInfoSection from "./sections/TravelInfoSection";
import PriceSummarySection from "./sections/PriceSummarySection";
import BookerInfoSection from "./sections/BookerInfoSection";
import LoadingSpinner from "components/LoadingSpinner";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import useValidate, { BaseType, ValidStateType } from "hooks/useValidate";
import {
  PassengerDetail,
  ReservationState,
  updateSearchParams,
} from "app/reservationSlice";
import { updatePayment } from "app/paymentSlice";
import { deleteMyOrders } from "app/myOrdersSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ScheduleType } from "types/scheduleType";
import {
  PriceType,
  calculateFilteredTotalPrice,
  calculateNewPrice,
} from "./utils/priceUtils";
import { isAxiosError } from "axios";
import UserService from "services/userService";
import { fetchUser } from "app/userSlice";
import useModalSheet from "hooks/overlay/useModalSheet";

/**
 * Checks if a given forms are filled.
 * If the form is an array of passenger details, check if all passengers have a name and birth date.
 * If the form is a BookerInfo object, check if the booker has a name, phone number, email, and birth date
 *
 * @param forms - An array of PassengerDetail objects or a BookerInfo object.
 * @returns A boolean indicating if all forms are filled.
 */
const isFormFilled = (forms: PassengerDetail[] | BookerInfo): boolean => {
  let isFormFilled = false;

  if (forms instanceof Array) {
    isFormFilled = forms.every(
      (passenger) => passenger.name && passenger.birthDate
    );
  } else {
    isFormFilled = Boolean(
      forms.name && forms.phone && forms.email && forms.birthDate
    );
  }

  return isFormFilled;
};

/**
 * Checks if all values in a given ValidStateType object are true.
 *
 * @param validState - A ValidStateType object.
 * @returns A boolean indicating if all values in the object are true.
 */
const isFormValid = (validState: ValidStateType<BaseType>) => {
  for (const iterator of Object.values(validState)) {
    if (!iterator) {
      return false;
    }
  }

  return true;
};

/**
 * Converts a ReservationInput object into a FormData object.
 *
 * @param reservationInput - A ReservationInput object that has reservation data.
 * @returns A FormData object containing the reservation data.
 */
const createReservationFormData = (reservationInput: ReservationInput) => {
  const reservationData = createReservationData(reservationInput);
  const formData = new FormData();
  Object.entries(reservationData).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

/**
 * Generates an array of passenger details with initial values.
 * The initial passenger detail objects have the following properties:
 * - name: empty string
 * - birthDate: empty string
 * - seat: a seat object from the selected seats array
 *
 * @param reservation - The reservation state object.
 * @returns An array of passenger detail objects with initial values.
 */
const getInitialPassengerDetails = (
  reservation: ReservationState
): PassengerDetail[] => {
  const passengerCount = reservation.searchParams.passengers;
  const seats = reservation.trainParams.selectedSeats;
  return Array.from({ length: passengerCount }, (_, index) => ({
    name: "",
    birthDate: "",
    seat: seats![index],
  }));
};

/**
 * Generates an object with initial values for passenger birthdate validation.
 * The keys are in the format of "birthDate{index}" and the values are all true.
 *
 * @param reservation - The reservation state object.
 * @returns An object with initial values for passenger birthdate validation.
 */
const getInitialPassengerValidState = (reservation: ReservationState) => {
  const passengerCount = reservation.searchParams.passengers;
  const passengerValidState: PassengerValidState = {};

  for (let i = 0; i < passengerCount; i++) {
    passengerValidState[`birthDate${i}`] = true;
  }
  return passengerValidState;
};

const initialBookerValidState = {
  name: true,
  phone: true,
  email: true,
  birthDate: true,
};

export type FormValidState = typeof initialBookerValidState;
export type PassengerValidState = Record<`birthDate${number}`, boolean>;

const PaymentBookingPage = () => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const { schedule } = useLocation().state as { schedule: ScheduleType };

  // redux
  const dispatch = useAppDispatch();
  const reservation = useAppSelector((state) => state.reservation);
  const { name, phone, email, birthDate } = useAppSelector(
    (state) => state.user
  );

  // Validation modal
  const [ValidationModal, setModalVisible] = useModalSheet({
    callbackOk: () => window.scrollTo({ top: 280, behavior: "smooth" }),
  });
  const [validationModalContent, setValidationModalContent] = useState("");

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [hanpassAgencyCode, setHanpassAgencyCode] = useState("");

  const [relatedGoods, setRelatedGoods] = useState<Coupon[]>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon>(null!); // 선택한 쿠폰 상태 관리
  const originalPrice = useRef<PriceType>(
    calculateFilteredTotalPrice(reservation)
  );
  const [price, setPrice] = useState(originalPrice.current); // 총액 상태

  // 계산
  const [bookerValidState, bookerValidate] = useValidate(
    initialBookerValidState
  );
  const [passengerValidState, passengerValidate] = useValidate(
    getInitialPassengerValidState(reservation)
  );

  // 예매자 정보 - userInfo없는 경우에만 서버로 전달하기
  const [bookerInfo, setBookerInfo] = useState<BookerInfo>({
    name,
    phone,
    email,
    birthDate,
  });

  // 승객 상세 정보
  const [passengerDetails, setPassengerDetails] = useState(() =>
    getInitialPassengerDetails(reservation)
  );

  // data
  const isBookerInfoVisible = !(name && phone && email && birthDate);
  const description = useRef("");
  const seats = reservation.trainParams.selectedSeats;
  const stationCode = schedule.arvRsStnCd;
  const reservationInput: ReservationInput = {
    selectedCoupon,
    bookerInfo: { ...bookerInfo, agencyCode: hanpassAgencyCode },
    passengerDetails,
    schedule,
    reservation,
  };

  // action
  useEffect(() => {
    const fetchRelatedGoods = async () => {
      try {
        const data = await TrainService.getRelatedGoods(stationCode);
        if (data && data.length > 0) {
          setSelectedCoupon(data[0]);
          setPrice(calculateNewPrice(originalPrice.current, data[0]));
          description.current = data[0].description;
        }
        setRelatedGoods(data);
      } catch (error) {
        console.error("결합상품 조회 실패:", error);
      }
    };

    fetchRelatedGoods();
  }, [stationCode]);

  // 탑승자 정보 변경 핸들러
  const handleChangePassengerDetail =
    (index: number, field: PassengerDetailField) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      switch (field) {
        case "birthDate":
          passengerValidate(e);
          break;
        default:
          break;
      }

      setPassengerDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];

        // 특정 인덱스의 탑승자 정보를 복사하고, 필요한 필드를 업데이트합니다.
        updatedDetails[index] = {
          ...updatedDetails[index],
          [field]: value,
        };

        dispatch(updateSearchParams({ passengerDetails: updatedDetails }));
        // 업데이트된 탑승자 정보 배열을 반환합니다.
        return updatedDetails;
      });
    };

  // Coupon selection change handler
  const handleCouponChange = (coupon: Coupon) => {
    setSelectedCoupon(coupon);
    setPrice(calculateNewPrice(originalPrice.current, coupon));
  };

  const validateBookerInfo = () => {
    const handleFail = (message: string) => {
      setModalVisible(true);
      setValidationModalContent(message);
    };

    if (!bookerInfo.name || !bookerInfo.name) {
      handleFail(intl.formatMessage({ id: "signup.enterName" }));
      return false;
    }

    if (!bookerInfo.phone) {
      handleFail(intl.formatMessage({ id: "signup.enterPhone" }));
      return false;
    }
    if (!bookerValidState.phone) {
      handleFail(intl.formatMessage({ id: "signup.phoneInvalid" }));
      return false;
    }

    if (!bookerInfo.email) {
      handleFail(intl.formatMessage({ id: "signup.enterEmail" }));
      return false;
    }
    if (!bookerValidState.email) {
      handleFail(intl.formatMessage({ id: "signup.emailInvalid" }));
      return false;
    }

    if (!bookerValidState.birthDate) {
      handleFail(intl.formatMessage({ id: "booking.invalidBirthDate" }));
      return false;
    }
    if (!bookerInfo.birthDate) {
      handleFail(intl.formatMessage({ id: "booking.birthDate" }));
      return false;
    }

    return true;
  };

  const handleReservation = async () => {
    // 예매자 정보 validation
    if (isBookerInfoVisible && !validateBookerInfo()) {
      return;
    }

    // 탑승자 정보 validation
    if (!isFormFilled(passengerDetails)) {
      setModalVisible(true);
      setValidationModalContent(
        intl.formatMessage({ id: "booking.enterPassengerDetails" })
      );
      return;
    }
    if (!isFormValid(passengerValidState)) {
      setModalVisible(true);
      setValidationModalContent(
        intl.formatMessage({ id: "booking.enterValidPassengerInfo" })
      );
      return;
    }

    const reservationFormData = createReservationFormData(reservationInput);

    setIsLoading(true);

    try {
      const { data } = await TrainService.reserveTicket(reservationFormData);

      // 예매 결과를 paymentParams에 저장
      dispatch(
        updatePayment({
          couponList: data.goodsList,
          amount: data.totalPrice, // 예매 총 금액
          finalAmount: data.purchasePrice, // 결제 금액
          transactionId: data.apiReserveNumber, // 거래 ID
          isPaid: true, // 결제 여부를 true로 설정
        })
      );

      dispatch(deleteMyOrders());

      // 처음 예매하는 경우 유저 정보 redux에 추가
      if (isBookerInfoVisible) {
        const userInfoResponse = await UserService.getMyInfo();
        dispatch(fetchUser(userInfoResponse));
      }

      navigate(`/payment/confirmation/${data.orderId}`, {
        state: {
          reservationDetails: data,
          schedule: schedule,
          seats,
          totalPrice: data.originalPrice,
          discount: data.totalDiscount,
          commission: data.totalCommission,
          finalPrice: data.totalPrice,
        },
      });
    } catch (error) {
      console.error("Reservation failed:", error);
      if (isAxiosError(error)) {
        if (error.response?.data.errorMessage === "INVALID_BIRTHDAY") {
          alert("탑승자에 맞는 유효한 생년월일을 입력하세요. ");
          return;
        }
        alert(error.response?.data.errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout text={intl.formatMessage({ id: "booking.title" })}>
      <Container
        maxWidth={false}
        sx={{
          mt: 2,
          p: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TravelInfoSection schedule={schedule} seats={seats} />

        <Divider />

        {isBookerInfoVisible && (
          <BookerInfoSection
            bookerInfo={bookerInfo}
            setBookerInfo={setBookerInfo}
            validState={bookerValidState}
            validate={bookerValidate}
          />
        )}

        {/* TODO: Props를 더 깔끔하게 내려주는 방법 없을까? 너무 많음 지금 - Context 사용? */}
        <PassengerInfoSection
          agencyCode={hanpassAgencyCode}
          setAgencyCode={setHanpassAgencyCode}
          bookerDetails={bookerInfo}
          passengerDetails={passengerDetails}
          setPassengerDetails={setPassengerDetails}
          onChangePassengerDetail={handleChangePassengerDetail}
          validState={passengerValidState}
        />

        <Divider />

        {/* 쿠폰 표시 섹션 */}
        <CouponSection
          selectedCoupon={selectedCoupon}
          handleCouponChange={handleCouponChange}
          relatedGoods={relatedGoods}
          description={description.current}
        />
        {/* 총액 표시 섹션 */}
        <PriceSummarySection
          totalPrice={price.originalPrice}
          discount={price.discount}
          commission={price.commission}
          finalPrice={price.paymentPrice}
        />

        {/* 예매 버튼 */}
        <Stack direction="row" gap={1}>
          <CustomButton
            id="booking.cancelText"
            variant="outlined"
            color="error"
            onClick={() => navigate(-1)}
            size="medium"
            style={{
              backgroundColor: theme.palette.white.main,
            }}
            disabled={isLoading}
          />
          <CustomButton
            id="booking.confirmText"
            onClick={handleReservation}
            size="medium"
            disabled={isLoading}
          />
        </Stack>
      </Container>
      {isLoading && <LoadingSpinner overlap />}
      <ValidationModal modal>
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{ fontWeight: "normal" }}
        >
          {validationModalContent}
        </Typography>
      </ValidationModal>
    </Layout>
  );
};

export default PaymentBookingPage;
