import { Grid, TextField, Typography } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import TravelPackCard from "components/lottecard/TravelPackCard";
import useFetchPoint from "hooks/useFetchPoint";
import useFormatPrice from "hooks/useFormatPrice";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { ProviderUtils } from "utils/providerUtils";

interface PointSectionProps {
  point: string;
  setPoint: React.Dispatch<React.SetStateAction<string>>;
  priceToPay: number;
}

const PointSection = ({ point, setPoint, priceToPay }: PointSectionProps) => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();
  const userPoint = useFetchPoint();
  const numericPoint = Number(point.replaceAll(",", ""));

  const [isFocused, setIsFocused] = useState(false);

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {intl.formatMessage({ id: "point.point" })}
      </Typography>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (Number(value) > userPoint) {
      alert(intl.formatMessage({ id: "point.cannotExceedPointsHeld" }));
      return;
    }

    setPoint(`${Number(value)}`);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setPoint(`${numericPoint || "0"}`);
  };

  const handleBlur = () => {
    setIsFocused(false);
    // if (numericPoint > userPoint) {
    //   alert(intl.formatMessage({ id: "point.cannotExceedPointsHeld" }));
    //   setPoint("0");
    //   return;
    // }
    setPoint(`${numericPoint.toLocaleString()}`);
  };

  const handleClick = () => {
    setPoint(`${Math.min(userPoint, priceToPay).toLocaleString()}`);
  };

  return (
    <CardLayout header={getHeader()}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="end"
        spacing={1}
        rowSpacing={1}
      >
        <Grid item xs={8} sx={{ textAlign: "start" }}>
          <Typography>
            {intl.formatMessage({ id: "point.points_held" })}{" "}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "end" }}>
          <Typography color="error" sx={{ fontWeight: "bold" }}>
            {formatPrice(userPoint)}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            type={isFocused ? "number" : "text"}
            fullWidth
            name="passengerName"
            value={point}
            // onChange={onChangePassengerDetail(index, "name")}
            placeholder={"0"}
            InputProps={{
              inputProps: { min: 0, max: userPoint },
              sx: {
                height: "48px",
                "& .MuiInputBase-input": {
                  textAlign: "end",
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                  margin: 0.3,
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0.3,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0.3,
                },
                "& input[type=text]": {
                  "-moz-appearance": "textfield",
                  margin: 1,
                },
                "& input[type=text]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 1,
                },
                "& input[type=text]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 1,
                },
              },
              endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
            }}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={3} alignSelf={"center"}>
          <CustomButton
            size="large"
            onClick={handleClick}
            disabled={Math.min(userPoint, priceToPay) === numericPoint}
          >
            {intl.formatMessage({ id: "point.useAll" })}
          </CustomButton>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        {intl.formatMessage({
          id: "point.using_points_decreases_payment_amount",
        })}
      </Typography>
      {/* 롯데카드 트래블팩 */}
      {ProviderUtils.provider?.provider === "lottetest" && (
        <TravelPackCard text={"트래블팩 구독하면 매월 1천원 적립"} chevron />
      )}
    </CardLayout>
  );
};

export default PointSection;
