import { Typography, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import { OrderType } from "types/orderType";
import { CancelFeeCheckResponse } from "services/paymentService";
import KakaoAccordion from "components/coupon/KakaoAccordion";
import CardLayout from "components/layout/CardLayout";

interface RelatedProductProps {
  order: OrderType;
  cancelFee?: CancelFeeCheckResponse;
}

const RelatedProduct = ({ order, cancelFee }: RelatedProductProps) => {
  const formatPrice = useFormatPrice();

  return (
    <CardLayout>
      <Stack gap={1} sx={{ width: "100%" }}>
        <Typography component="div" variant="body1" sx={{ fontWeight: "bold" }}>
          {order.goodsList.name}
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            <FormattedMessage id="orders.price" />:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(order.goodsList.price)}
          </Typography>
        </Stack>

        {cancelFee && cancelFee.goodsCancelFee != null && (
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="body1">
              <FormattedMessage id="orders.cancelFee" />:
            </Typography>
            <Typography variant="body1">
              {cancelFee.goodsCancelFee.toLocaleString()}
              <FormattedMessage id="payment.koreanWon" />
            </Typography>
          </Stack>
        )}
      </Stack>
      {!(order.orderStatus === "CANCELED") && (
        <KakaoAccordion descriptionKey={order.goodsList.description} />
      )}
    </CardLayout>
  );
};

export default RelatedProduct;
