import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";
import useIsScrolling from "hooks/useIsScrolling";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const ScrollToTopButton = () => {
  const isScrolling = useIsScrolling();

  return (
    <ScrollToTopButtonWrapper
      sx={{
        visibility: isScrolling ? "hidden" : "visible",
        opacity: isScrolling ? 0.05 : 1,
        transition: "all 0.2s ease-in-out",
      }}
      onClick={isScrolling ? undefined : scrollToTop}
    >
      <ArrowUpwardIcon fontSize="large" />
    </ScrollToTopButtonWrapper>
  );
};

export default ScrollToTopButton;

const ScrollToTopButtonWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",

  height: 40,
  width: 40,

  position: "fixed",
  right: "56px",
  bottom: "12px",

  color: "white",
  backgroundColor: theme.palette.primary.main,
}));
