import { ProviderUtils } from "utils/providerUtils";

let apiBaseUrl: string;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "prod":
    if (!ProviderUtils.provider) {
      apiBaseUrl = "https://api.lacha.co.kr";
      break;
    }
    apiBaseUrl = "https://apinew.hcclab.co.kr";
    break;
  case "dev":
    apiBaseUrl = "https://devfo.hcclab.com";
    break;
  case "playground":
    apiBaseUrl = "https://devpg.hcclab.com";
    break;
  case "staging":
    apiBaseUrl = "https://staging.hcclab.co.kr";
    break;
  default:
    apiBaseUrl = "http://localhost:8081";
    apiBaseUrl = "https://devfo.hcclab.com";
}

export default apiBaseUrl;

// localhost - npm start
export const __DEV__ = process.env.NODE_ENV === "development";

// build result - npm run build
export const __PROD__ = process.env.NODE_ENV === "production";
