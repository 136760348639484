import axios from "axios";
import apiBaseUrl from "const/properties";
import TokenService from "./tokenService";

// axios 인스턴스 기본 설정
const hanacardApi = axios.create({
  baseURL: apiBaseUrl + "/hanacard",
});

// 요청 인터셉터 추가
hanacardApi.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
hanacardApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if it's a token expired error
    if (
      error.response?.status === 401 &&
      error.response?.data.errorMessage === "ACCESS_TOKEN_EXPIRED"
    ) {
      // Handle access token expiration
      return TokenService.handleAccessTokenExpired(error, error.config);
    }
    return Promise.reject(error);
  }
);

interface CnoTsReponse {
  cno: string;
  ts: string;
}

export class HanacardService {
  static async getCnoAndTs(): Promise<CnoTsReponse> {
    try {
      const response = await hanacardApi.post("/timestamp");
      return response.data;
    } catch (error) {
      console.error("Hanacard nativation failed");
      throw error;
    }
  }
}
