import { useReducer } from "react";
import REGEX from "const/regex";
import useDebounce from "./useDebounce";

export type BaseType = {
  [key: string]: boolean;
};

export type ValidStateType<T> = {
  [key in keyof T]: boolean;
};

type ActionType<T> = {
  name: keyof T;
  value: boolean;
};

type Reducer<T> = (
  state: ValidStateType<T>,
  action: ActionType<T>
) => ValidStateType<T>;

const reducer = <T>(
  state: ValidStateType<T>,
  action: ActionType<T>
): ValidStateType<T> => {
  // Check if action name without number is in REGEX object's key
  // if (Object.keys(REGEX).includes(String(action.name).replace(/[0-9]/g, ""))) {
  if (Object.keys(REGEX).includes(String(action.name).replace(/[0-9]/g, ""))) {
    return {
      ...state,
      [action.name]: action.value,
    };
  }
  return state;
};

// event.target.name과 initialState의 key 값이 동일하거나, 숫자만 추가하도록 코드를 짜야 한다. 
const useValidate = <T extends BaseType>(initialState: T) => {
  const [validState, dispatch] = useReducer<Reducer<T>>(reducer, initialState);
  const debounce = useDebounce();

  const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    debounce(() => {
      dispatch({ name, value: REGEX[name.replace(/[0-9]/g, "")].test(value) });
    });
  };

  return [validState, validate] as const;
};

export default useValidate;

//

// export type ValidStateType = Record<string, boolean>;

// type ActionType = {
//   name: string;
//   value: boolean;
// };

// const reducer = (state: ValidStateType, action: ActionType): ValidStateType => {
//   // Check if action name without number is in REGEX object's key
//   if (Object.keys(REGEX).includes(action.name.replace(/[0-9]/g, ""))) {
//     return {
//       ...state,
//       [action.name]: action.value,
//     };
//   }
//   return state;
// };

// const useValidate = (initialState: ValidStateType) => {
//   const [validState, dispatch] = useReducer(reducer, initialState);
//   const DEBOUNCE_DELAY = 300;
//   // const timeoutId = useRef<number | null>(null);
//   const timeoutId = useRef<ReturnType<typeof setTimeout>>();

//   const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     if (timeoutId.current) {
//       clearTimeout(timeoutId.current);
//     }
//     timeoutId.current = setTimeout(() => {
//       dispatch({ name, value: REGEX[name.replace(/[0-9]/g, "")].test(value) });
//     }, DEBOUNCE_DELAY);
//   };

//   return [validState, validate] as const;
// };

// export default useValidate;
