import {
  BOTTOM_SHEET_HEIGHT,
  HEADER_HEIGHT,
} from "components/bottomSheet/BottomSheet";
import { useEffect, useRef } from "react";

interface BottomSheetMetrics {
  touchStart: {
    sheetY: number; // touchstart에서 BottomSheet의 최상단 모서리의 Y값
    touchY: number; // touchstart에서 터치 포인트의 Y값
  };
  touchMove: {
    prevTouchY: number; // 다음 touchmove 이벤트 핸들러에서 필요한 터치 포인트 Y값을 저장
    movingDirection: "none" | "down" | "up"; // 유저가 터치를 움직이고 있는 방향
  };
  isContentAreaTouched: boolean;
}

const defaultMetrics: BottomSheetMetrics = {
  touchStart: {
    sheetY: 0,
    touchY: 0,
  },
  touchMove: {
    prevTouchY: 0,
    movingDirection: "none",
  },
  isContentAreaTouched: false,
};

const useBottomSheet = (MIN_Y: number, bottomSheetHeight: number) => {
  const sheet = useRef<HTMLDivElement>(null!);
  const content = useRef<HTMLDivElement>(null!);

  const metrics = useRef(defaultMetrics);

  // Touch Event 핸들러들을 등록한다.
  useEffect(() => {
    const canUserMoveBottomSheet = () => {
      const { isContentAreaTouched } = metrics.current;

      // 바텀시트에서 컨텐츠 영역이 아닌 부분을 터치하면 항상 바텀시트를 움직입니다.
      if (!isContentAreaTouched) {
        return true;
      }
      return false;
    };

    const handleTouchStart = (e: TouchEvent) => {
      const touchY = e.touches[0].clientY;
      const { touchStart, touchMove } = metrics.current;
      // console.log(touchY);

      touchStart.sheetY = sheet.current.getBoundingClientRect().y;
      touchStart.touchY = touchY;

      touchMove.prevTouchY = touchY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();

      if (canUserMoveBottomSheet()) {
        const { touchStart, touchMove } = metrics.current;
        const currentTouch = e.touches[0];

        if (touchMove.prevTouchY < currentTouch.clientY) {
          touchMove.movingDirection = "down";
        }

        if (touchMove.prevTouchY > currentTouch.clientY) {
          touchMove.movingDirection = "up";
        }

        touchMove.prevTouchY = currentTouch.clientY;

        // 터치 시작점에서부터 현재 터치 포인트까지의 변화된 y값 - 양수면 아래로, 음수면 위로 이동
        const touchOffset = currentTouch.clientY - touchStart.touchY;

        // 새로운 BottomSheet의 최상단 모서리의 Y값
        let nextSheetY = touchStart.sheetY + touchOffset;

        // nextSheetY 는 MIN_Y와 MAX_Y 사이의 값으로 clamp 되어야 한다
        if (nextSheetY <= MIN_Y) {
          nextSheetY = MIN_Y;
        }

        if (nextSheetY >= window.innerHeight) {
          nextSheetY = window.innerHeight - HEADER_HEIGHT;
        }

        // BottomSheet가 이동할 거리
        const diff = nextSheetY - MIN_Y;

        // sheet 위치 갱신.
        sheet.current.style.setProperty("transform", `translateY(${diff}px)`);
      }
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const { touchStart, touchMove } = metrics.current;
      const touchY = e.changedTouches[0].clientY;

      // // Snap Animation
      const currentSheetY = sheet.current.getBoundingClientRect().y;
      if (currentSheetY !== MIN_Y) {
        if (touchMove.movingDirection === "down") {
          sheet.current.style.setProperty(
            "transform",
            `translateY(${BOTTOM_SHEET_HEIGHT * 2}px)`
          );
        }
        if (touchMove.movingDirection === "up") {
          sheet.current.style.setProperty(
            "transform",
            `translateY(${touchStart.touchY - touchY}px)`
          );
        }
      }
      // metrics 초기화.
      metrics.current = defaultMetrics;
    };

    const bottomSheetNode = sheet.current;

    bottomSheetNode?.addEventListener("touchstart", handleTouchStart);
    bottomSheetNode?.addEventListener("touchmove", handleTouchMove);
    bottomSheetNode?.addEventListener("touchend", handleTouchEnd);

    return () => {
      bottomSheetNode?.removeEventListener("touchstart", handleTouchStart);
      bottomSheetNode?.removeEventListener("touchmove", handleTouchMove);
      bottomSheetNode?.removeEventListener("touchend", handleTouchEnd);
    };
  }, [MIN_Y, bottomSheetHeight]);

  // content 영역을 터치하는 것을 기록합니다.
  useEffect(() => {
    const handleTouchStart = () => {
      metrics.current.isContentAreaTouched = true;
    };

    const handleTouchEnd = () => {
      metrics.current.isContentAreaTouched = false;
    };

    const contentNode = content.current;

    contentNode.addEventListener("touchstart", handleTouchStart);
    contentNode.addEventListener("touchend", handleTouchEnd);

    return () => {
      contentNode.removeEventListener("touchstart", handleTouchStart);
      contentNode.addEventListener("touchend", handleTouchEnd);
    };
  }, []);

  return { sheet, content };
};

export default useBottomSheet;
