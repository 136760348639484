/**
 * @file
 * @description
 * 고객사별 provider 관련 utility class.

 * 새로운 고객사가 추가된 경우 다음을 수정하세요
 *  - Subdomain, Testdomain 운영/개발용 provider 추가
 *  - ProviderUtils에 is[provider] static property 추가
 *  - (Optional) 필요한 category 있다면 CategoryProviderUtils에 추가
 *  - (Optional) LoginProviderPage.tsx 파일도 필요한 경우 수정 
*/

// TOOD: Builder pattern
// class Subdomain {
//   public static readonly Hanpass = new Subdomain("hpnew", false);
//   public static readonly Paybooc = new Subdomain("paybooc", true);
//   public static readonly Lottecard = new Subdomain("lottecard", false, true);
//   public static readonly Hanacard = new Subdomain("hanacard", false, true);

//   private constructor(
//     public readonly provider: string,
//     public readonly loginAvailable: boolean,
//     public readonly point: boolean = false,
//     public readonly discount: boolean = true,
//     public readonly darkMode: boolean = false
//   ) {}
// }

// TODO: provider field 지정하지 않은 경우 compile error 던지게 수정하기

// https://stackoverflow.com/questions/62264580/is-there-a-method-for-using-a-nested-class-as-a-type-in-typescript

class Domain {
  private static DomainBuilder = class {
    private _provider?: string;
    private _loginAvailable: boolean = true;
    private _point: boolean = false;
    private _discount: boolean = true;
    private _darkMode: boolean = false;

    public provider(provider: string): this {
      this._provider = provider;
      return this;
    }

    public loginAvailable(loginAvailable: boolean): this {
      this._loginAvailable = loginAvailable;
      return this;
    }

    public point(point: boolean): this {
      this._point = point;
      return this;
    }

    public discount(discount: boolean): this {
      this._discount = discount;
      return this;
    }

    public darkMode(darkMode: boolean): this {
      this._darkMode = darkMode;
      return this;
    }

    public build(): Domain {
      if (!this._provider) {
        throw new Error("provider field is required");
      }
      return new Domain(
        this._provider,
        this._loginAvailable,
        this._point,
        this._discount,
        this._darkMode
      );
    }
  };

  protected constructor(
    public readonly provider: string,
    public readonly loginAvailable: boolean,
    public readonly point: boolean,
    public readonly discount: boolean,
    public readonly darkMode: boolean
  ) {}

  protected static Builder(): InstanceType<typeof Domain.DomainBuilder> {
    return new Domain.DomainBuilder();
  }
}

class Subdomain extends Domain {
  // Subdomain Objects created with Builder pattern
  public static Hanpass: Domain = Subdomain.Builder()
    .provider("hpnew")
    .loginAvailable(false)
    .build();
  public static Paybooc: Domain = Subdomain.Builder()
    .provider("paybooc")
    .build();
  public static Lottecard: Domain = Subdomain.Builder()
    .provider("lottecard")
    .loginAvailable(false)
    .point(true)
    .build();
  public static Hanacard: Domain = Subdomain.Builder()
    .provider("hanacard")
    .loginAvailable(false)
    .build();
  public static Travelwallet: Domain = Subdomain.Builder()
    .provider("travelwallet")
    .build();
  public static GME: Domain = Subdomain.Builder()
    .provider("gme")
    .loginAvailable(false)
    .build();
  public static RedTable: Domain = Subdomain.Builder()
    .provider("redtable")
    .loginAvailable(false)
    .build();
  public static KTmobile: Domain = Subdomain.Builder()
    .provider("ktm")
    .point(true)
    .darkMode(true)
    .build();
  public static Mame: Domain = Subdomain.Builder()
    .provider("mame")
    .discount(false)
    .darkMode(true)
    .build();
  public static Currency: Domain = Subdomain.Builder().provider("cch").build();
  public static Card: Domain = Subdomain.Builder().provider("card").build();
}

class TestDomain extends Domain {
  // TestDomain Objects created with Builder pattern
  public static Hanpass: Domain = TestDomain.Builder()
    .provider("test")
    .loginAvailable(false)
    .build();
  public static Paybooc: Domain = TestDomain.Builder().provider("test").build();
  public static Lottecard: Domain = TestDomain.Builder()
    .provider("lottetest")
    .loginAvailable(false)
    .point(true)
    .build();
  public static Hanacard: Domain = TestDomain.Builder()
    .provider("hanatest")
    .loginAvailable(false)
    .build();
  public static Travelwallet: Domain = TestDomain.Builder()
    .provider("test")
    .build();
  public static GME: Domain = TestDomain.Builder()
    .provider("gmetest")
    .loginAvailable(false)
    .build();
  public static RedTable: Domain = TestDomain.Builder()
    .provider("rttest")
    .loginAvailable(false)
    .build();
  public static KTmobile: Domain = TestDomain.Builder()
    .provider("test")
    .point(true)
    .darkMode(true)
    .build();
  public static Mame: Domain = TestDomain.Builder()
    .provider("test")
    .discount(false)
    .darkMode(true)
    .build();
  public static Currency: Domain = TestDomain.Builder().provider("cch").build();
  public static Card: Domain = TestDomain.Builder().provider("card").build();

  public static Dev: Domain = TestDomain.Builder().provider("test").build();
  public static Local: Domain = TestDomain.Builder()
    .provider("localhost")
    .point(true)
    .build();
  public static Staging: Domain = TestDomain.Builder()
    .provider("lachastaging")
    .build();
  public static Playground: Domain = TestDomain.Builder()
    .provider("playground")
    .build();
}

class BaseProviderUtils {
  private static getProviderListFrom(domain: typeof Domain) {
    return Object.getOwnPropertyNames(domain)
      .filter((key) => {
        // 제외할 기본 static 필드 목록
        const excludedFields = ["length", "name", "prototype"];

        return (
          !excludedFields.includes(key) && // 기본 필드 제외
          typeof domain[key as keyof typeof domain] !== "function"
        );
      }) // 함수가 아닌 static 필드만 필터링
      .map((key) => domain[key as keyof typeof domain]);
  }

  // 운영 고객사 리스트
  public static prodProviderList = this.getProviderListFrom(Subdomain);

  // 개발 고객사 리스트
  public static devProviderList = this.getProviderListFrom(TestDomain);

  // 전체 고객사 리스트
  public static providerList = [
    ...this.prodProviderList,
    ...this.devProviderList,
  ];

  // provider
  private static getProvider() {
    const subdomain = window.location.hostname.split(".")[0];

    const getProvider = (subdomain: string) => {
      return this.providerList.find(
        (provider) => provider.provider === subdomain
      );
    };

    return getProvider(subdomain);
  }

  // public static readonly provider = BaseProviderUtils.getProvider();
  // 위 코드로 test 코드 실행하면 localhost 나옴 - static property가 initialize되는 시간 관련된 문제
  // https://chatgpt.com/c/6710701e-0f60-8002-86e3-c518bce9b336
  public static get provider() {
    return this.getProvider();
  }
}

class CategoryProviderUtils extends BaseProviderUtils {
  static discountAvailable = !this.provider || this.provider.discount;
  static pointAvailable = !this.provider || this.provider.point;
  static darkModeAvailable = !this.provider || this.provider.darkMode;
  static loginAvailable = !this.provider || this.provider.loginAvailable;

  static isDollarProvider = this.provider?.provider === "cch";
  static isDev = ["test", "localhost"].includes(
    this.provider?.provider as string
  );
}

export class ProviderUtils extends CategoryProviderUtils {
  // Dynamically set isProvider static propeties for each provider
  // TypeScript’s static type checking is based on explicitly declared properties
  static readonly isHanpass: boolean;
  static readonly isPaybooc: boolean;
  static readonly isLottecard: boolean;
  static readonly isHanacard: boolean;
  static readonly isTravelwallet: boolean;
  static readonly isGME: boolean;
  static readonly isRedTable: boolean;
  static readonly isKTmobile: boolean;
  static readonly isMame: boolean;
  static readonly isCurrency: boolean;
  static readonly isCard: boolean;

  static setIsProviderProperties() {
    const excludedFields = ["length", "name", "prototype"];
    Object.getOwnPropertyNames(Subdomain)
      .filter(
        (key) =>
          !excludedFields.includes(key) && // 기본 필드 제외
          typeof Subdomain[key as keyof typeof Subdomain] !== "function" // 함수 제외
      )
      .forEach((key) => {
        const propertyName = `is${key}`;
        Object.defineProperty(ProviderUtils, propertyName, {
          value: [
            Subdomain[key as keyof typeof Subdomain].provider,
            TestDomain[key as keyof typeof TestDomain].provider,
          ].includes(this.provider?.provider as string),
          writable: false,
          configurable: false,
        });
      });
  }
}

ProviderUtils.setIsProviderProperties();
