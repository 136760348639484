import { useEffect } from "react";
import QuickLinks from "./sections/QuickLinks";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import useContinueSearching from "hooks/useContinueSearching";
import { StorageUtils } from "utils/storageUtils";

const StationListPage = () => {
  const intl = useIntl();
  const location = useLocation();
  const fieldName: "departure" | "arrival" =
    location.state?.fieldName ?? StorageUtils.StationFieldName.get();

  useEffect(() => {
    StorageUtils.StationFieldName.set(fieldName);
  });

  return (
    <Layout
      text={
        fieldName === "departure"
          ? intl.formatMessage({ id: `stationSelectionPopup.selectDeparture` }) // "출발지 선택"
          : intl.formatMessage({ id: `stationSelectionPopup.selectArrival` }) // "도착지 선택"
      }
      onBack={useContinueSearching()}
    >
      <QuickLinks />
    </Layout>
  );
};

export default StationListPage;
