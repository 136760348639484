import { Grid, Typography } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import TrainIcon from "@mui/icons-material/Train";
import LotteCardBanner from "assets/images/lotte_banner.webp";
import HanaCardBanner from "assets/images/hana_banner.webp";
import { HEADER_HEIGHT } from "const/layout";
import { ProviderUtils } from "utils/providerUtils";

const BackgroundImage = () => {
  if (ProviderUtils.isLottecard) {
    return (
      <div
        style={{
          position: "relative",
          top: `${HEADER_HEIGHT}px`,
          height: "calc(50vw)",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${LotteCardBanner})`,
        }}
      />
    );
  }

  if (ProviderUtils.isHanacard) {
    return (
      <div
        style={{
          position: "relative",
          top: `${HEADER_HEIGHT}px`,
          height: "calc(50vw)",
          width: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${HanaCardBanner})`,
        }}
      />
    );
  }

  return (
    <CustomButton
      style={{
        height: "200px",
        position: "relative",
        top: "24px",
        padding: "16px",
        cursor: "default",
        borderRadius: 0,
      }}
      color="secondary"
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={7.5} sx={{ mx: 2 }}>
          <Typography sx={{ fontSize: "1rem", textAlign: "start" }}>
            Let's
          </Typography>
          <Typography
            sx={{
              fontSize: "1.625rem",
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: 1.2,
            }}
          >
            Book Your Next Trip
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TrainIcon
            fontSize="large"
            sx={{
              fontSize: "220px",
              position: "absolute",
              right: -50,
              top: -20,
            }}
          />
        </Grid>
      </Grid>
    </CustomButton>
  );
};

export default BackgroundImage;
