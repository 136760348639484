import { Card, Typography, List, ListItem } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Stack } from "@mui/system";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";

const BookingGuidelines = () => {
  const { isKorean } = useLanguages();

  const guidelines = [
    <>
      <FormattedMessage id="booking.noSoloTrainReservation" />{" "}
      <FormattedMessage id="booking.vouchersDelivery" />
    </>,
    <FormattedMessage id="booking.discountForAdultsOnly" />,
    <FormattedMessage id="booking.variableDiscountRates" />,
    <FormattedMessage id="booking.generalSeatReservation" />,
    <FormattedMessage id="booking.realTimeReservations" />,
    <FormattedMessage id="booking.reservationRestrictions" />,
    <FormattedMessage id="booking.noChangeAfterReservation" />,
    <FormattedMessage id="booking.purchaseLimit" />,
    <FormattedMessage id="booking.notProvidedFeatures" />,
    <FormattedMessage id="booking.differentLook" />,
    <FormattedMessage id="booking.optionProducts" />,
    <FormattedMessage id="booking.cancellationPolicy" />,
  ];

  const isPayboocKorean = isKorean && ProviderUtils.isPaybooc;

  if (isPayboocKorean) {
    guidelines.unshift(
      <div>
        <p>열차 티켓 확인 방법:</p> 페이북 앱 접속 - 혜택 - KTX 할인 - KTX 예매
        바로가기 - 우측 상단 줄3개 클릭 - 로그인 - 예매내역 확인
      </div>
    );
  }

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        <FormattedMessage id="booking.mustReadBeforePurchase" />
      </Typography>
      <List sx={{ p: 0 }}>
        {guidelines.map((text, index) => (
          <ListItem key={index} sx={{ p: 0.5 }}>
            <Stack direction="row" alignItems="flex-start">
              <span style={{ marginInlineEnd: "8px" }}>•</span>
              <Typography
                variant="caption"
                color={
                  index < (isPayboocKorean ? 4 : 3)
                    ? // ? "#222"
                      "text.primary"
                    : "text.secondary"
                }
                sx={
                  index < (isPayboocKorean ? 4 : 3)
                    ? {
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                {text}
              </Typography>
            </Stack>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default BookingGuidelines;
