import { Box, Card } from "@mui/material";
import { styled } from "@mui/material/styles";

interface BottomSheetHeaderProps {
  height: number;
}

const BottomSheetHeader = ({ height }: BottomSheetHeaderProps) => {
  return (
    <HeaderWrapper sx={{ height }}>
      <Handle />
    </HeaderWrapper>
  );
};

export default BottomSheetHeader;

const HeaderWrapper = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
`;

const Handle = styled(Box)`
  width: 32px;
  height: 4px;
  border-radius: 2px;
  background-color: #d0d0d0;
`;
