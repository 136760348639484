import { Card, Grid, Typography } from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import TravelPackBanner from "components/lottecard/TravelPackBanner";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from 'utils/providerUtils';

const Banner = () => {
  const { isKorean } = useLanguages();

  const getBannerContent = () => {
  if (ProviderUtils.isLottecard) {
      return <TravelPackBanner />;
    }

    if (ProviderUtils.isHanacard) {
      return (
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            fontSize: "0.9rem",
            fontWeight: "bold",
            textAlign: "start",
          }}
        >
          {isKorean
            ? "결제가 완료되면, 입력하신 휴대폰번호 알림톡으로 예약정보가 발송됩니다."
            : "Once the payment is completed, the reservation information will be sent to your mobile phone number."}
          <br />
          {isKorean
            ? "(발송처 : 발권대행사 '라쿠카라차')"
            : "(Sender: Ticketing Agency 'Lacucaracha')"}
        </Typography>
      );
    }

    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          minHeight: "80px",
          color: "black.main",
        }}
      >
        <Grid item xs={8}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: 1.2,
            }}
          >
            라차에서 예약하면
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: 1.4,
              wordBreak: "keep-all",
            }}
          >
            KTX 최대 20% 할인
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <StyleIcon
            sx={{
              fontSize: `150px`,
              position: "absolute",
              top: -20,
              right: -40,
              transform: "scaleY(-1)",
              // color: "grey.700",
              color: "homeBanner.textColor",
              opacity: "0.7",
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Card
      sx={{
        m: 2,
        p: 2,
        px: 3,
        bgcolor: "homeBanner.bgColor",
        border: "none",
        position: "relative",
      }}
    >
      {getBannerContent()}
    </Card>
  );
};

export default Banner;
