import { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { IntlProvider } from "react-intl";
import * as locales from "assets/locales";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import AppBody from "./router/AppBody";
import { useAppSelector } from "app/hooks";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ko";
import Metatag from "Metatag";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "components/ScrollToTop";
import {
  darkDefaultTheme,
  darkPayboocTheme,
  lightDefaultTheme,
  lightPayboocTheme,
  lightGMETheme,
  lightLotteCardTheme,
  lightHanaCardTheme,
  lightRedTableTheme,
} from "theme/theme";
import { ProviderUtils } from "utils/providerUtils";
import useFetchWebBridge from "hooks/useFetchWebBridge";

// @media ${({ theme }) => theme.device.tablet} {
// flex-direction: column;
// height: calc(90vw * 16 / 9);
// }

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",

  margin: "auto",
  maxWidth: "100%",
  minHeight: "100vh",

  overflow: "hidden",

  backgroundColor: theme.background,

  boxSizing: "border-box",
}));

const messages = locales;

type MessageType = Record<string, string>;

const getTheme = (theme: "light" | "dark") => {
  if (ProviderUtils.isPaybooc) {
    return theme === "light" ? lightPayboocTheme : darkPayboocTheme;
  }

  if (ProviderUtils.isLottecard) {
    return lightLotteCardTheme;
  }

  if (ProviderUtils.isHanacard) {
    return lightHanaCardTheme;
  }

  if (ProviderUtils.isGME) {
    return lightGMETheme;
  }

  if (ProviderUtils.isRedTable) {
    return lightRedTableTheme;
  }

  return theme === "light" ? lightDefaultTheme : darkDefaultTheme;
};

const App = () => {
  useFetchWebBridge(); // For fetching lottecard web bridge file
  const { theme } = useAppSelector((state) => state.theme);
  const colorTheme = getTheme(theme);

  // TODO: 고객사마다 기본 언어 설정 바뀌도록 수정
  const language =
    useAppSelector((state) => state.user?.language) ||
    // localStorage.getItem("lang") ||
    "ko";

  // const setScreenSize = () => {
  //   const vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty("--vh", `${vh}px`);
  // };

  useEffect(() => {
    // setScreenSize();

    document.documentElement.dir = language !== "ur" ? "ltr" : "rtl";
    // window.addEventListener("resize", setScreenSize);
    // return () => window.removeEventListener("resize", setScreenSize);
  });

  return (
    <ThemeProvider theme={colorTheme}>
      <HelmetProvider>
        <IntlProvider
          locale={language}
          messages={messages[language] as MessageType}
        >
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={language === "ko" ? "ko" : "en"}
          >
            <CssBaseline />
            <Metatag />

            <Wrapper>
              <Container maxWidth={false} disableGutters>
                <ScrollToTop />
                <AppBody />
              </Container>
            </Wrapper>
          </LocalizationProvider>
        </IntlProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default App;
