import Header from "components/layout/Header";
import BookingForm from "./sections/BookingForm";
import Banner from "./sections/Banner";
import BackgroundImage from "./sections/BackgroundImage";
import { Container } from "@mui/system";
import { useEffect } from "react";
import Footer from "components/layout/Footer";
import { Stack } from "@mui/material";
import { StorageUtils } from "utils/storageUtils";
import { ProviderUtils } from "utils/providerUtils";
import history from "history/browser";
import { closeWebview } from "utils/lottecardUtils";
import { useAppDispatch } from "app/hooks";
import { deleteMyOrders, resetFilterSetting } from "app/myOrdersSlice";

const HomePage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(deleteMyOrders());
    dispatch(resetFilterSetting());

    StorageUtils.TrainTab.remove();
    StorageUtils.ScrollY.remove();
  }, [dispatch]);

  useEffect(() => {
    StorageUtils.StationFieldName.remove();

    const listenBackEvent = () => {
      if (ProviderUtils.isHanpass) {
        StorageUtils.HanpassBack.remove();
        window.location.href = "http://finish";
      }
      if (ProviderUtils.isLottecard) {
        closeWebview();
      }
    };

    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        listenBackEvent();
      }
    });

    return () => {
      historyEvent();
      // window.history.replaceState(null, "");
    };
  }, []);

  return (
    <>
      <Header />
      <BackgroundImage />
      <Stack justifyContent="space-between" sx={{ minHeight: "80vh" }}>
        <Container maxWidth="md" sx={{ position: "relative", top: "-10px" }}>
          <BookingForm />
          {ProviderUtils.provider?.provider !== "lottecard" && <Banner />}
        </Container>
      </Stack>
      <Footer />
    </>
  );
};

export default HomePage;
