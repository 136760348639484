import React from "react";
import {
  Stack,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIntl } from "react-intl";
import parse from "html-react-parser";
import { checkAllAgree, PaymentAgreementType } from "../PaymentPageContent";

interface AgreementsSectionProps {
  agreements: PaymentAgreementType;
  setAgreements: React.Dispatch<React.SetStateAction<PaymentAgreementType>>;
  handleAllAgree: () => void;
}

const AgreementsSection = ({
  agreements,
  setAgreements,
  handleAllAgree,
}: AgreementsSectionProps) => {
  const intl = useIntl();
  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreements({ ...agreements, [e.target.name]: e.target.checked });
  };

  return (
    <Stack gap={1}>
      <Card sx={{ textAlign: "start" }}>
        <FormControlLabel
          aria-label="consent-all"
          sx={{
            px: 2,
            py: 1,
            "& .MuiFormControlLabel-label": {
              fontSize: "1rem",
              fontWeight: "bold",
            },
            width: "100%",
            textAlign: "start",
          }}
          onClick={(e) => e.stopPropagation()}
          control={
            <Checkbox
              checked={checkAllAgree(agreements)}
              onChange={handleAllAgree}
              name="consent-all"
              color="secondary"
            />
          }
          label={intl.formatMessage({
            id: "common.agreeAll",
          })}
        />
        <Divider />
        {Object.keys(agreements).map((term, index) => (
          <Accordion
            disableGutters
            key={term}
            // expanded={agreements[term]}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                minHeight: "48px",
                "& .MuiAccordionSummary-content": {
                  m: 0,
                },
              }}
            >
              <FormControlLabel
                aria-label={`checkbox-${term}`}
                onClick={(e) => e.stopPropagation()}
                control={
                  <Checkbox
                    checked={agreements[term as keyof PaymentAgreementType]}
                    onChange={handleAgreementChange}
                    name={term}
                    color="secondary"
                  />
                }
                label={intl.formatMessage({
                  id: `term${index + 1}.title`,
                })}
                sx={{ textAlign: "start", wordBreak: "keep-all" }}
              />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                lineHeight: 0.8,
                fontSize: "0.1rem",
                overflow: "scroll",
              }}
            >
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: `term${index + 1}.content`,
                  }),
                }}
              /> */}
              {parse(
                `${intl.formatMessage({ id: `term${index + 1}.content` })}`
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Card>
    </Stack>
  );
};

export default AgreementsSection;
