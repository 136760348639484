import { useAppDispatch, useAppSelector } from "app/hooks";
import { updatePoint } from "app/userSlice";
import { useEffect, useState } from "react";
import UserService from "services/userService";

const useFetchPoint = () => {
  const [point, setPoint] = useState(
    useAppSelector((state) => state.user.point)
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getPoint = async () => {
      const myPoint = await UserService.getPoint();
      dispatch(updatePoint(myPoint));
      setPoint(myPoint);
    };
    getPoint();
  }, [dispatch]);

  return point;
};

export default useFetchPoint;
