import { Stack } from "@mui/material";
import { styled } from "@mui/system";
import useLanguages from "hooks/useLanguages";
import { Link, useLocation } from "react-router-dom";
import { ProviderUtils } from "utils/providerUtils";

const Footer = () => {
  const { isKorean } = useLanguages();
  const { pathname } = useLocation();

  const termsOfUse = isKorean ? "이용약관" : "Terms of Use";
  const privacyPolicy = isKorean ? "개인정보처리방침" : "Privacy Policy";
  const customerCenter = isKorean ? "고객센터" : "Customer Center";

  return (
    <Wrapper gap={2} justifyContent="center" alignItems="center">
      {!ProviderUtils.provider && pathname === "/" && (
        <Stack alignItems="start" sx={{ textAlign: "start", px: 1 }}>
          {isKorean ? (
            <>
              <p>
                주식회사 라쿠카라차 | 서울시 강남구 선릉로 86길 26, 4층 (06197)
              </p>
              <p>사업자번호: 657-88-00880</p>
              <p>통신판매업신고번호: 2021-서울강남-01710</p>
              <p>대표이사: 이윤상</p>
              <p>개인정보보호책임자: 유호상</p>
              <p>고객센터: 02-568-1220</p>
            </>
          ) : (
            <>
              <p>
                &copy; Lacucaracha | 26, Seolleung-ro 86-gil, Gangnam-gu, Seoul
                (06197)
              </p>
              <p>Tel: 02-568-1220</p>
              <p>Chief Executive Officer: Yoonsang Lee</p>
              <p>Privacy Management Officer: Hosang Yoo</p>
            </>
          )}
        </Stack>
      )}

      <Stack alignItems="center" gap={1}>
        <Stack direction="row" gap={0.5}>
          <Link to="/usage-terms">{termsOfUse}</Link>
          <p>|</p>
          <Link to="/privacy-policy">{privacyPolicy}</Link>
          <p>|</p>
          <Link to="/customer-center">{customerCenter}</Link>
        </Stack>

        <div>Copyright © LACUCARACHA Co., Ltd. All rights reserved.</div>
      </Stack>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled(Stack)`
  text-align: center;
  font-size: 12px;
  color: #eee;
  background-color: #030310cc;
  margin-top: 32px;
  padding: 16px 0;
  width: 100%;

  a {
    color: #eee;
    text-decoration: none;
  }
`;
