import { useCallback, useRef } from "react";

const useDebounce = (delay = 300) => {
  // const timeoutId = useRef<number | null>(null);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const debounce = useCallback(
    (callback: () => void) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(callback, delay);

      return timeoutId.current;
    },
    [delay]
  );

  return debounce;
};

export default useDebounce;
