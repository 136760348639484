import { Grid, Modal, Stack } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useLanguages from "../useLanguages";

interface CustomBottomSheetProps {
  children: React.ReactNode;
  variant?: "button" | "icon"; // button - 확인/취소 버튼 있는 모달, icon - 취소 아이콘 있는 모달
  height?: string;
  modal?: boolean;
  okText?: string | React.ReactElement;
  cancellable?: boolean;
  cancelText?: string;
}

interface useModalSheetProps {
  defaultVisible?: boolean;
  callbackOk?: () => void; // modal 확인 버튼 클릭 시 실행할 콜백
  callbackCancel?: () => void; // modal 취소 버튼 클릭 시 실행할 콜백
}

const useModalSheet = ({
  defaultVisible = false,
  callbackOk,
  callbackCancel,
}: useModalSheetProps = {}) => {
  const { isKorean } = useLanguages();
  const [visible, setVisible] = useState(defaultVisible);

  // TODO: css height %로 지정하는 방법(?)
  const CustomBottomSheet = ({
    children,
    variant = "button",
    modal,
    cancellable = false,
    okText = isKorean ? "확인" : "Ok",
    cancelText = isKorean ? "취소" : "Cancel",
  }: CustomBottomSheetProps) => {
    const hideModalSheet = () => {
      setVisible(false);
    };

    const handleOk = () => {
      callbackOk?.();
      hideModalSheet();
    };

    const handleCancel = () => {
      callbackCancel?.();
      hideModalSheet();
    };

    const cardStyle = modal
      ? {
          width: "clamp(280px, 80vw, 440px)",
        }
      : ({
          width: "100vw",
          position: "fixed",
          bottom: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          maxHeight: "70dvh",
        } as const);

    return (
      <Modal
        open={visible}
        onClose={handleCancel}
        aria-labelledby="modal"
        // aria-describedby="modal-modal-description"
        sx={{ zIndex: 100000001 }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }} // modal인 경우 필요
        >
          {/* 실제 모달 content */}
          <CardLayout sx={{ ...cardStyle, overflow: "auto" }}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{ position: "relative" }}
            >
              {variant === "icon" && (
                <CloseIcon
                  onClick={handleCancel}
                  sx={{ position: "absolute", right: 2 }}
                />
              )}
              <Grid
                item
                xs
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  wordBreak: "keep-all",
                }}
              >
                {children}
              </Grid>
              {variant === "button" && (
                <Grid item xs={1} gridAutoRows="1fr">
                  <Stack
                    direction="row"
                    justifySelf="flex-end"
                    sx={{
                      mt: 2,
                      width: "100%",
                    }}
                    gap={1}
                  >
                    {cancellable && (
                      <CustomButton
                        variant="outlined"
                        color="error"
                        size="medium"
                        onClick={handleCancel}
                        sx={{ width: "100%" }}
                      >
                        {cancelText}
                      </CustomButton>
                    )}
                    <CustomButton
                      size="medium"
                      onClick={handleOk}
                      sx={{ width: "100%" }}
                    >
                      {okText}
                    </CustomButton>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </CardLayout>
        </Stack>
      </Modal>
    );
  };

  return [CustomBottomSheet, setVisible] as const;
};

export default useModalSheet;
