import { styled } from "@mui/material/styles";
import { Chip, Stack, Tab, Tabs, TabsProps } from "@mui/material";
import { HANACARD_SUBTAB_HEIGHT, HANACARD_TAB_HEIGHT } from "const/layout";
import { useState } from "react";

const enum MobilityTab {
  KTX = "KTX",
  RENTAL = "렌터카",
}

const tabLabels = [
  MobilityTab.KTX,
  // MobilityTab.RENTAL // 12월 중 추가 예정
];

const SubTab = () => {
  // const match = useMediaQuery("(min-width:321px)");

  const [isLoading, setIsLoading] = useState(false);
  const selectedTab = MobilityTab.KTX;

  return (
    <StyledTabs
      value={selectedTab}
      // variant={match ? "fullWidth" : "scrollable"}
      variant={"fullWidth"}
      indicatorColor="primary"
      textColor="primary"
      sx={{
        position: "fixed",
        top: `calc(env(safe-area-inset-top) + ${HANACARD_TAB_HEIGHT}px)`,
        mx: "24px",
        width: "calc(100% - 48px)",
        bgcolor: "#FFF",
        zIndex: 9999999,
        borderBottom: `1px solid #EAF1F2`,
        height: `${HANACARD_SUBTAB_HEIGHT}px`,
      }}
    >
      {tabLabels.map((label, index) => (
        <Tab
          key={index}
          value={label}
          // onClick={isLoading ? undefined : navigateTo.bind(null, label)}
          disableRipple
          label={
            <Chip
              label={<Stack justifyContent="center">{label}</Stack>}
              size="medium"
              color={label === selectedTab ? "primary" : "transparent"}
              sx={{
                // px: "10px",
                wordBreak: "keep-all",
                position: "relative",
                fontSize: "14px",
                height: "30px",
                color: label === selectedTab ? "#FFF" : "#536067",
              }}
            />
          }
          sx={{
            p: "11px 0",
            letterSpacing: "-0.95px",
            minWidth: "88px",
          }}
        />
      ))}
    </StyledTabs>
  );
};

export default SubTab;

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <div className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});
