import { Stack, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import useTranslateStation from "hooks/useTranslateStation";
import { formatDate, formatTime } from "utils/formatUtils";
import Arrow from "components/Arrow";
import useFormatDuration from "hooks/useFormatDuration";
import TrainLogo from "components/TrainLogo";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import { useAppSelector } from "app/hooks";
import { ScheduleType } from "types/scheduleType";
import CardLayout from "components/layout/CardLayout";
import { SeatDetails } from "components/seat/Seat";
import useFormatSeatDetails from "hooks/useFormatSeatDetails";
import { ProviderUtils } from "utils/providerUtils";

interface TravelInfoSectionProps {
  schedule: ScheduleType;
  seats: SeatDetails[];
}

const TravelInfoSection = ({ schedule, seats }: TravelInfoSectionProps) => {
  const reservation = useAppSelector((state) => state.reservation);
  const intl = useIntl();
  const formatDuration = useFormatDuration();
  const formatDateTime = useFormatDateToLocaleString();
  const formatSeatDetails = useFormatSeatDetails();
  const translateStation = useTranslateStation();
  const theme = useTheme();

  const date = formatDate(schedule.dptDt);
  // const trainType = trainGroupCodeMap[`${schedule.trnGpCd}`] || "Unknown";
  const trainNumber = schedule.trnNo;
  const departureTime = formatTime(schedule.dptTm);
  const arrivalTime = formatTime(schedule.arvTm);
  const duration = formatDuration(schedule.runHm);
  const departureName = schedule.dptRsStnNm;
  const arrivalName = schedule.arvRsStnNm;
  // const seat = seats[0];
  const stlbTrnClsfCd = schedule.stlbTrnClsfCd;

  // trnPsgTpCd 및 trnPsrmClCd에 해당하는 요소 찾기
  const discountDetails = reservation.trainParams.costDetails?.find(
    (detail) => detail.trnPsgTpCd === "1" && detail.trnPsrmClCd === "1"
  );

  // sellDscntRt 값이 존재하면 사용, 그렇지 않으면 0
  const discountRate = discountDetails ? discountDetails.sellDscntRt : 0;

  const getHeader = () => {
    return (
      <Typography
        variant="subtitle2"
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {intl.formatMessage({ id: "trainMain.dateOfDeparture" })}
        </Typography>
        <Typography sx={{ textAlign: "end" }}>
          {date} ({formatDateTime(date, { weekday: "short" })})
        </Typography>
      </Typography>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <Stack gap={1} sx={{ mt: 1, width: "100%" }}>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          sx={{ width: "100%" }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "booking.passengers" })}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage(
              { id: "booking.passengersDetail" },
              {
                total: reservation.searchParams.passengers,
                adults: reservation.searchParams.adults,
                children: reservation.searchParams.children,
              }
            )}
          </Typography>
        </Stack>
        {/* Seat Details */}
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "booking.seats" })}
          </Typography>
          <Typography variant="body2">{formatSeatDetails(seats)}</Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        gap={2}
        sx={{ width: "100%" }}
      >
        <Stack direction="row" gap={1}>
          {/* <Chip
          label={trainType}
          color="primary"
          size="small"
          sx={{ px: 0.5 }}
        /> */}
          <TrainLogo stlbTrnClsfCd={stlbTrnClsfCd} />
          <Typography
            variant="caption"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {trainNumber}
          </Typography>
        </Stack>
        {ProviderUtils.discountAvailable && discountRate !== 0 && (
          <Typography
            variant="caption"
            sx={{
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              borderRadius: "8px",
              px: 1,
              py: 0.2,
              textAlign: "end",
              wordBreak: "break-all",
              maxWidth: "60%",
            }}
          >
            {intl.formatMessage(
              { id: "booking.discountMessage" },
              { rate: discountRate }
            )}
          </Typography>
        )}
      </Stack>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        sx={{ px: 2, textAlign: "center", width: "100%" }}
      >
        <Stack alignItems="center" gap={0.5} sx={{ height: "100%" }}>
          <Typography variant="h5">{departureTime}</Typography>
          <Typography component="p">
            {translateStation(departureName)}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          <Arrow />
          <Typography variant="body1">{duration}</Typography>
        </Stack>
        <Stack alignItems="center" gap={0.5} sx={{ height: "100%" }}>
          <Typography variant="h5">{arrivalTime}</Typography>
          <Typography component="p">{translateStation(arrivalName)}</Typography>
        </Stack>
      </Stack>
    </CardLayout>
  );
};

export default TravelInfoSection;
