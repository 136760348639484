import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KakaoAccordion from "components/coupon/KakaoAccordion";
import { FormattedMessage } from "react-intl";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import { OrderType } from "types/orderType";
import useLanguages from "hooks/useLanguages";

interface PriceDetailSectionProps {
  order: OrderType;
}

const PriceDetailSection = ({ order }: PriceDetailSectionProps) => {
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  return (
    <Accordion
      disableGutters
      square
      sx={{
        alignSelf: "end",
        boxShadow: "none",
        "& .MuiButtonBase-root": { px: 0 },
        "& .MuiAccordionSummary-content": { my: 0 },
        "&::before": { display: "none" },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="orders.goToDetail"
        id="orders.goToDetail"
        sx={{
          minHeight: "24px",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            justifyContent: "end",
          },
        }}
      >
        <Typography variant="caption" color="text.secondary">
          <FormattedMessage id="orders.goToDetail" />
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <CardLayout
          sx={{
            p: 1.5,
            boxShadow: "none",
            bgcolor: "secondary.light",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body2">
                <FormattedMessage id="orders.trainTicket" />:
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Typography variant="body2">
                {formatPrice(Number(order.totalPrice) - order.goodsList.price)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                {isKorean ? "카카오" : "Kakao"} T:
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <Typography variant="body2">
                {formatPrice(order.goodsList.price)}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <KakaoAccordion descriptionKey={order.goodsList.description} />
        </CardLayout>
      </AccordionDetails>
    </Accordion>
  );
};

export default PriceDetailSection;
