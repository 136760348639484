import React, { useState } from "react";
import { Autocomplete, TextField, useTheme, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import CustomButton from "components/button/CustomButton";
import useTranslateStation from "hooks/useTranslateStation";
import { selectSearchParams, updateSearchParams } from "app/reservationSlice";
import useContinueSearching from "hooks/useContinueSearching";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { TrainResponse } from "services/trainService";
import { StorageUtils } from "utils/storageUtils";

interface SearchBarProps {
  stations: TrainResponse[];
  saveToRecentSearches: (link: TrainResponse) => void;
}

const SearchBar = ({ stations, saveToRecentSearches }: SearchBarProps) => {
  const continueSearching = useContinueSearching();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(selectSearchParams);

  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const translateStation = useTranslateStation();

  const handleSearch = (
    e: React.FormEvent<HTMLFormElement>,
    searchTerm: string
  ) => {
    e.preventDefault();

    const selectedStation = stations?.find(
      (station) => translateStation(station.stationName) === searchTerm
    );

    if (selectedStation) {
      const fieldName = StorageUtils.StationFieldName.get();
      const otherFieldName =
        fieldName === "departure" ? "arrival" : "departure";

      // 출발지와 도착지가 같은 경우 alert 표시
      if (
        selectedStation.stationCode === searchParams[`${otherFieldName}Code`]
      ) {
        alert(
          intl.formatMessage({ id: "stationSelectionPopup.sameStationError" })
        );
        return;
      }

      // Call saveToRecentSearches with the selected station
      saveToRecentSearches(selectedStation);
      StorageUtils.StationFieldName.remove();

      dispatch(
        updateSearchParams({
          [`${fieldName}`]: selectedStation.stationName,
          [`${fieldName}Code`]: selectedStation.stationCode,
        })
      );

      continueSearching();
    }
  };

  return (
    <form
      onSubmit={(e) => handleSearch(e, searchTerm)}
      style={{
        margin: "16px 0",
        width: "100%",
      }}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={8}>
          <Autocomplete
            options={stations.map((station) =>
              translateStation(station.stationName)
            )}
            onInputChange={(event, newInputValue) => {
              setSearchTerm(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "stationSelectionPopup.searchForCityOrStation",
                })}
                size="small"
                sx={{
                  bgcolor: theme.palette.white.main,
                  borderRadius: theme.shape.borderRadius,
                }}
              />
            )}
            freeSolo
            sx={{
              // border: "1px solid blue",
              "& .MuiOutlinedInput-root": {
                // border: "1px solid yellow",
                padding: 0,
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #ebebeb",
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomButton
            id="stationSelectionPopup.searchButtonText"
            size="medium"
            disabled={!searchTerm}
            style={{ fontWeight: "500" }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchBar;
