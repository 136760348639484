import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Card } from "@mui/material";
import Instructions from "./sections/Instructions";
import SeatMap from "./sections/SeatMap";
import SeatSelectionActions from "./sections/SeatSelectionActions";
import TrainInfoIndicator from "./sections/TrainInfoIndicator";
import Layout from "components/layout/Layout";
import CarTabs from "./sections/CarTabs";
import useTranslateStation from "hooks/useTranslateStation";
import { selectSearchParams, updateTrainParams } from "app/reservationSlice";
import { Stack } from "@mui/system";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { SeatDetails } from "components/seat/Seat";
import { CarType } from "types/carType";

type RequiredSeatDetails = Required<SeatDetails>;
type SeatAvailableType = "Y" | "N";

const sortSelectedSeats = (selectedSeats: RequiredSeatDetails[]) => {
  const newSeats = selectedSeats.map((seat) => seat.seatSpec);
  newSeats.sort();
  return newSeats.join(", ");
};

const SeatSelectionPage = () => {
  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(selectSearchParams);
  const totalPassengers = searchParams.passengers;
  const [seatAppFlg, setSeatAppFlg] = useState<SeatAvailableType>("N");

  const intl = useIntl();
  const translateStation = useTranslateStation();
  const navigate = useNavigate(); // 페이지 이동을 위한 navigate 함수 사용
  const {
    state: { cars, restSeats, schedule },
  } = useLocation(); // cars, restSeats : 이전 페이지에서 받은 차량 데이터

  // 선택된 좌석과 탭 상태 관리
  const [selectedSeats, setSelectedSeats] = useState<RequiredSeatDetails[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const { departure = "Departure Station", arrival = "Arrival Station" } =
    searchParams;

  // 호차 탭 레이블 생성
  const tabLabels: string[] = cars.map((car: CarType) =>
    intl.formatMessage(
      { id: "seatSelection.carNumber" },
      { number: +car.scarNo }
    )
  );

  const handleFinalizeReservation = async () => {
    // setAutoAssigned(false);

    // Check if any seats are selected
    if (selectedSeats.length === 0) {
      alert(intl.formatMessage({ id: "seatSelection.seatNotSelectError" }));
      return;
    }

    // Map selected seats for reservation
    const seatsForReservation = selectedSeats.map(
      ({ scarNo, seatNo, seatSpec }: RequiredSeatDetails) => ({
        scarNo,
        seatNo: seatNo.padStart(3, "0"),
        seatSpec,
      })
    );

    // Update reservation state with selected seats
    dispatch(
      updateTrainParams({
        selectedSeats: seatsForReservation,
        seatAppFlg,
      })
    );

    // Compare the number of selected seats with the total number of passengers
    if (seatsForReservation.length < totalPassengers) {
      alert(
        intl.formatMessage(
          { id: "seatSelection.seatNotEnoughError" },
          { seats: totalPassengers }
        )
      );
      return;
    }

    // Navigate to booking detail with the selected schedule and seats
    // navigate(`/booking-detail/${schedule.trnNo}`, {
    navigate(`/payment/booking`, {
      state: {
        schedule,
        seats: seatsForReservation,
      },
    });
  };

  // 좌석 선택 핸들러 업데이트
  const handleSeatSelect = ({
    seatNo,
    seatSpec,
  }: Pick<RequiredSeatDetails, "seatNo" | "seatSpec">) => {
    const currentCarNo = cars[selectedTab].scarNo;

    setSeatAppFlg("Y");
    setSelectedSeats((prevSeats) => {
      // const newSeat = { scarNo: currentCarNo, seatNo: seatId };
      // 이미 선택된 좌석이면 제거, 아니면 추가
      if (
        prevSeats.some(
          (prevSeat) =>
            Number(prevSeat.seatNo) === Number(seatNo) &&
            prevSeat.scarNo === currentCarNo
        )
      ) {
        // if (prevSeats.some((prevSeat) => prevSeat.seatNo === seatId)) {
        return prevSeats.filter(
          (prevSeat) =>
            Number(prevSeat.seatNo) !== Number(seatNo) ||
            prevSeat.scarNo !== currentCarNo
        );
        // return prevSeats.filter((prevSeat) => prevSeat.seatNo !== seatId);
      }

      // 선택 가능한 최대 좌석 수를 초과하는 경우 경고
      if (prevSeats.length + 1 > searchParams.passengers) {
        // alert(`최대 ${totalPassengers}개의 좌석을 선택할 수 있습니다.`);
        alert(intl.formatMessage({ id: "seatSelection.seatExceedError" }));
        return prevSeats;
      }

      const newSeat = {
        scarNo: currentCarNo,
        seatNo: seatNo.padStart(3, "0"),
        seatSpec,
      };

      return [...prevSeats, newSeat];
    });
  };

  // 자동 좌석 할당 핸들러
  const handleAutoAssign = () => {
    setSeatAppFlg("N");

    const availableSeats = cars[selectedTab].restSeatList.filter(
      (seat: { salePsbFlg: SeatAvailableType }) => seat.salePsbFlg === "Y" // 판매 가능한 좌석 필터링
    );
    // Compare the number of selected seats with the total number of passengers
    if (availableSeats.length >= totalPassengers) {
      const randomSeats: RequiredSeatDetails[] = [];
      for (let i = 0; i < totalPassengers; i++) {
        const randomSeatIndex = Math.floor(
          Math.random() * availableSeats.length
        );
        const randomSeat = availableSeats.splice(randomSeatIndex, 1)[0];
        randomSeats.push(randomSeat);
      }

      // Map selected seats for reservation
      const seatsForReservation = randomSeats.map(
        ({
          seatNo,
          seatSpec,
        }: Pick<RequiredSeatDetails, "seatNo" | "seatSpec">) => ({
          scarNo: cars[selectedTab].scarNo,
          seatNo: seatNo.padStart(3, "0"),
          seatSpec,
        })
      );

      // Update reservation state with selected seats
      dispatch(
        updateTrainParams({
          selectedSeats: seatsForReservation,
          seatAppFlg,
        })
      );

      // Navigate to booking detail with the selected schedule and seats
      // navigate(`/booking-detail/${schedule.trnNo}`, {
      navigate(`/payment/booking`, {
        state: {
          schedule,
          seats: seatsForReservation,
        },
      });
    } else {
      alert("사용 가능한 좌석이 없습니다.");
    }
  };

  // // 좌석 예약 핸들러
  // const handleReserveSeats = () => {
  //   if (selectedSeats.length === 0) {
  //     alert("최소 하나의 좌석을 선택해 주세요.");
  //   } else {
  //     // 선택된 좌석 ID들을 문자열로 변환
  //     const selectedSeatIds = selectedSeats
  //       .map((seat) => seat.seatNo)
  //       .join(", ");
  //     // 예약 상태 업데이트
  //     updateState("reservation", {
  //       searchParams: {
  //         ...reservation.searchParams,
  //         selectedSeats: selectedSeatIds,
  //       },
  //     });
  //     navigate(-1); // 이전 페이지로 이동
  //   }
  // };

  // 탭 변경 핸들러
  const handleTabChange = (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    // Check if there are any selected seats
    if (selectedSeats.length > 0 && newValue !== selectedTab) {
      alert("한 호차에서 예매를 완료해주세요.");
      return;
    }

    setSelectedTab(newValue);
  };

  return (
    <Layout text={intl.formatMessage({ id: "seatSelection.title" })}>
      <Stack gap={2}>
        <Instructions />
        <Card>
          <TrainInfoIndicator
            schedule={schedule}
            car={cars[selectedTab]}
            restSeat={restSeats[selectedTab]}
          />
        </Card>
        <Card sx={{ pb: 2, px: 2 }}>
          <CarTabs
            value={selectedTab}
            onChange={handleTabChange}
            tabLabels={tabLabels}
          />
          <Typography variant="body1" align="center" gutterBottom>
            {intl.formatMessage(
              { id: "seatSelection.stationDirection" },
              { station: translateStation(arrival) }
            )}
          </Typography>
          <SeatMap
            seats={cars[selectedTab]?.restSeatList}
            onSeatSelect={handleSeatSelect}
            selectedSeats={selectedSeats}
            scar={cars[selectedTab]}
          />
          <Typography variant="body1" align="center" gutterBottom>
            {intl.formatMessage(
              { id: "seatSelection.stationDirection" },
              { station: translateStation(departure) }
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            {intl.formatMessage({ id: "seatSelection.selectedSeatNumber" })}:
            <Box
              component="span"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              {sortSelectedSeats(selectedSeats)}
            </Box>
          </Typography>
        </Card>

        <SeatSelectionActions
          onResetSelection={() => setSelectedSeats([])}
          onAutoAssign={handleAutoAssign}
          onFinalizeReservation={handleFinalizeReservation}
        />
      </Stack>
    </Layout>
  );
};

export default SeatSelectionPage;
