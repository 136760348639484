import { Stack, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import CustomButton from "components/button/CustomButton";
import PaymentIcon from "@mui/icons-material/Payment";
import { useNavigate, useOutletContext } from "react-router-dom";
import CardBalance from "./components/CardBalance";
import TransactionList from "./components/TransactionList";
import { CardContext } from "./CardPageLayout";
import { useEffect, useState } from "react";
import CardService from "services/cardService";
import LoadingSpinner from "components/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchTransactions } from "app/cardsSlice";

const CardHome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentCard } = useOutletContext<CardContext>();
  const {
    acnRmd: balance,
    iapCdno: cardNumber,
    iapCrdStcd: status,
  } = currentCard || {};

  const transactionList = useAppSelector(
    (state) =>
      state.cards.find((card) => card.iapCdno === cardNumber)?.transactionList
  );
  const [isLoading, setIsLoading] = useState(false);

  const navigateToCardDetail = () => {
    navigate(`/card/detail`);
  };

  const navigateToTransactions = () => {
    navigate(`/card/${cardNumber}/transactions`);
  };

  useEffect(() => {
    if (!transactionList) {
      const getTransactionList = async () => {
        setIsLoading(true);
        try {
          const { data } = await CardService.getTransactionList(cardNumber);
          if (data) {
            dispatch(
              fetchTransactions({
                cardNumber,
                transactions: data.list,
              })
            );
          }
        } catch {
        } finally {
          setIsLoading(false);
        }
      };

      getTransactionList();
    }
  }, [cardNumber, dispatch, transactionList]);

  return (
    status && (
      <Stack gap={1}>
        <CardLayout>
          <Stack gap={1} sx={{ width: "100%" }}>
            <CardBalance balance={balance} />
            <CustomButton
              size="small"
              variant="outlined"
              onClick={navigateToCardDetail}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={1}
                sx={{ py: 0.5 }}
              >
                <PaymentIcon fontSize="medium" />
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Manage
                </Typography>
              </Stack>
            </CustomButton>
            {/* <CustomButton size="small">
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                sx={{ py: 1 }}
              >
                <PaidIcon fontSize="medium" />
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Pay
                </Typography>
              </Stack>
            </CustomButton> */}
          </Stack>
        </CardLayout>

        <CardLayout>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Recent Transactions
            </Typography>

            {isLoading ? (
              <LoadingSpinner height="80px" />
            ) : (
              <TransactionList transactions={transactionList?.slice(0, 5)} />
            )}

            <CustomButton
              variant="outlined"
              size="medium"
              onClick={navigateToTransactions}
            >
              Show more
            </CustomButton>
          </Stack>
        </CardLayout>
      </Stack>
    )
  );
};

export default CardHome;
