import { Stack, Typography } from "@mui/material";
import HanpassBottomSheet from "components/bottomSheet/HanpassBottomSheet";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { HanpassCouponInterface } from "services/userService";
import useLanguages from "hooks/useLanguages";

interface HanpassCouponProps {
  coupons: HanpassCouponInterface[];
  selectedCoupon: HanpassCouponInterface | undefined | null;
  setSelectedCoupon: React.Dispatch<
    React.SetStateAction<HanpassCouponInterface | null | undefined>
  >;
}

const HanpassCoupon = ({
  coupons,
  selectedCoupon,
  setSelectedCoupon,
}: HanpassCouponProps) => {
  const { isKorean } = useLanguages();
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {isKorean ? "한패스 쿠폰" : "Hanpass coupon"}
      </Typography>
    );
  };

  const showHanpassBottomSheet = () => {
    setBottomSheetVisible(true);
  };

  return (
    <>
      <CardLayout header={getHeader()}>
        <CustomButton
          variant="outlined"
          size="medium"
          disabled={!coupons.length}
          onClick={showHanpassBottomSheet}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            sx={{ width: "100%", fontWeight: "normal" }}
          >
            {coupons.length === 0
              ? "적용 가능 쿠폰 없음"
              : selectedCoupon?.couponDescription ?? "쿠폰 적용안함"}
            <ArrowDropDownIcon sx={{ position: "absolute", right: 6 }} />
          </Stack>
        </CustomButton>
      </CardLayout>
      {bottomSheetVisible && (
        <HanpassBottomSheet
          coupons={coupons}
          setBottomSheetVisible={setBottomSheetVisible}
          setSelectedCoupon={setSelectedCoupon}
        />
      )}
    </>
  );
};

export default HanpassCoupon;
