import { Card, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectSearchParams, updateSearchParams } from "app/reservationSlice";
import StationListSectionHeader from "components/layout/StationListSectionHeader";
import { frequentStations, popularRoutes } from "const/linksData";
import useContinueSearching from "hooks/useContinueSearching";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import TrainService, { TrainResponse } from "services/trainService";
import QuickLinkList from "./QuickLinkList";
import SearchBar from "./SearchBar";
import { StorageUtils } from "utils/storageUtils";

/**
 * A utility type that takes an existing type `T` and a set of keys `K` that are present in `T`.
 * It returns a new type that is identical to `T`, but with the specified keys `K` marked as required.
 *
 * @template T - The original type to be modified.
 * @template K - A set of keys that are present in `T`, which will be marked as required in the resulting type.
 * @returns A new type that is identical to `T`, but with the specified keys `K` marked as required.
 */
type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type SearchSection =
  | "recentSearches"
  | "popularRoutes"
  | "frequentStations"
  | "apiStations";

export type LinkItem = WithRequired<
  Partial<
    TrainResponse & {
      arrivalCode: string;
      departureCode: string;
      path: string;
    }
  >,
  "stationName"
>;

/**
 * Checks if the given item is a LinkItem.
 *
 * @param {LinkItem | TrainResponse} item - The item to check.
 * @return {item is LinkItem} - True if the item is a LinkItem, false otherwise.
 */
const isLink = (item: LinkItem | TrainResponse): item is LinkItem => {
  if ("arrivalCode" in item) {
    return true;
  }
  return false;
};

const QuickLinks = () => {
  // hooks
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const continueSearching = useContinueSearching();

  // state
  const [stations, setStations] = useState<TrainResponse[]>([]);
  const [recentSearchesState, setRecentSearchesState] = useState<
    TrainResponse[]
  >(JSON.parse(StorageUtils.RecentSearches.get() || "[]"));

  // data
  const searchParams = useAppSelector(selectSearchParams);
  const fieldName = StorageUtils.StationFieldName.get();

  // effect
  useEffect(() => {
    const fetchStations = async () => {
      try {
        const data = await TrainService.getStations();
        setStations(data);
      } catch (error) {
        console.error("역 목록을 가져오는데 실패했습니다.", error);
      }
    };

    fetchStations();
  }, []);

  const saveToRecentSearches = useCallback(
    (link: TrainResponse) => {
      const index = recentSearchesState.findIndex(
        (item) => item.stationCode === link.stationCode
      );

      if (index > -1) {
        // Station is already in the list
        setRecentSearchesState((prev) => {
          prev.splice(index, 1);
          const current = [link, ...prev];
          StorageUtils.RecentSearches.set(JSON.stringify(current));

          return current;
        });
      } else {
        // Station is not in the list
        setRecentSearchesState((prev) => {
          const current = [link, ...prev].slice(0, 6);
          StorageUtils.RecentSearches.set(JSON.stringify(current));

          return current;
        });
      }
    },
    [recentSearchesState]
  );

  const clearRecentSearches = () => {
    StorageUtils.RecentSearches.remove();
    setRecentSearchesState([]);
  };

  const handleLinkClick = useCallback(
    (link: TrainResponse | LinkItem) => {
      const { departureCode, arrivalCode } = searchParams ?? {};

      // 인기 노선 선택 처리
      if (isLink(link)) {
        const [departure, arrival] = link.stationName.split(" → ");
        const { departureCode, arrivalCode } = link;

        dispatch(
          updateSearchParams({
            departure,
            arrival,
            departureCode,
            arrivalCode,
          })
        );

        continueSearching();
        return;
      }

      // 출발역과 도착역과 같은지 확인
      if (
        (fieldName === "departure" && link.stationCode === arrivalCode) ||
        (fieldName === "arrival" && link.stationCode === departureCode)
      ) {
        alert(
          intl.formatMessage({ id: "stationSelectionPopup.sameStationError" })
        );
        return;
      }

      saveToRecentSearches(link);
      StorageUtils.StationFieldName.remove();

      // 기타 링크 선택 처리
      dispatch(
        updateSearchParams({
          [`${fieldName}`]: link.stationName,
          [`${fieldName}Code`]: link.stationCode,
        })
      );

      continueSearching();
    },
    [
      continueSearching,
      dispatch,
      fieldName,
      intl,
      saveToRecentSearches,
      searchParams,
    ]
  );

  return (
    <Stack direction="column" alignItems="center">
      <SearchBar
        stations={stations}
        saveToRecentSearches={saveToRecentSearches}
      />

      <Stack gap={1.5} sx={{ mb: 1 }}>
        {/* Recent search */}
        {recentSearchesState.length > 0 && (
          <Card>
            <StationListSectionHeader
              id="recentlySearched"
              onClick={clearRecentSearches}
            />
            <QuickLinkList
              items={recentSearchesState}
              section="recentSearches"
              handleLinkClick={handleLinkClick}
            />
          </Card>
        )}

        {/* Popular routes */}
        <Card>
          <StationListSectionHeader id="popularRoutes" />
          <QuickLinkList
            items={popularRoutes}
            section="popularRoutes"
            handleLinkClick={handleLinkClick}
            gridColumn={1}
          />
        </Card>

        {/* Frequent stations */}
        <Card>
          <StationListSectionHeader id="frequentlyVisited" />
          <QuickLinkList
            items={frequentStations}
            section="frequentStations"
            handleLinkClick={handleLinkClick}
          />
        </Card>

        {/* all stations */}
        <Card>
          <StationListSectionHeader id="allStations" />
          <QuickLinkList
            items={stations}
            section="apiStations"
            handleLinkClick={handleLinkClick}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default QuickLinks;
