import defaultLogo from "assets/images/logo/logo.png";
import payboocLogo from "assets/images/logo/paybooc_logo.png";
import gmeLogo from "assets/images/logo/gme_logo.png";
import lottecardLogo from "assets/images/logo/lottecard_logo.png";
import hanacardLogo from "assets/images/logo/hanacard_logo.png";
import hanacardMyReservationButton from "assets/images/hanacard_button.png";
import { useNavigate } from "react-router-dom";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import ThemeToggle from "components/ThemeToggle";
import { styled } from "@mui/material/styles";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Stack } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";
import { useAppSelector } from "app/hooks";
import CardService from "services/cardService";
import ReservationListIcon from "components/hanacard/ReservationListIcon";
import { ProviderUtils } from "utils/providerUtils";

const getLogo = () => {
  if (ProviderUtils.isPaybooc) {
    return payboocLogo;
  }

  if (ProviderUtils.isLottecard) {
    return lottecardLogo;
  }

  if (ProviderUtils.isHanacard) {
    return hanacardLogo;
  }

  if (ProviderUtils.isGME) {
    return gmeLogo;
  }

  return defaultLogo;
};

const getLogoHeight = () => {
  if (ProviderUtils.isGME) {
    return 20;
  }
};

const getLogoWidth = () => {
  if (ProviderUtils.isGME) {
    return 74;
  }

  if (
    ProviderUtils.isHanacard ||
    ProviderUtils.isLottecard ||
    ProviderUtils.isPaybooc
  ) {
    return 120;
  }

  return 54;
};

const Header = () => {
  const navigate = useNavigate();

  const { userId } = useAppSelector((state) => state.user);

  const handleMenuClick = () => {
    navigate("/user-settings");
  };

  return (
    <Wrapper
      style={{
        height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,

        padding: "0 16px",
        paddingTop: `env(safe-area-inset-top)`,
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {/* Lotte Card에서만 보여주기 */}
        {ProviderUtils.isLottecard && (
          <IconButton
            onClick={closeWebview}
            size="small"
            edge="end"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Logo
          src={getLogo()}
          alt="LACHA Logo"
          width={getLogoWidth()}
          height={getLogoHeight()}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          "& > * ": {
            cursor: "pointer",
          },
        }}
      >
        {ProviderUtils.isDev &&
          ProviderUtils.isCard &&
          // [
          //   TestProvider.Dev,
          //   TestProvider.Local,
          //   SubdomainProvider.Card,
          // ].includes(ProviderUtils.provider as SubdomainProvider)
          userId && (
            <p
              onClick={() => {
                CardService.checkSignUp()
                  .then(() => {
                    // console.log("go to card page");
                    navigate("/card");
                  })
                  .catch(() => {
                    // console.log("go to signup page");
                    navigate("/card/signup", {});
                  });
              }}
            >
              CARD
            </p>
          )}
        {ProviderUtils.darkModeAvailable && <ThemeToggle />}
        <LanguageSelectIcon />
        {ProviderUtils.isHanacard ? (
          <>
            <ReservationListIcon handleMenuClick={handleMenuClick} />
            <img
              onClick={handleMenuClick}
              src={hanacardMyReservationButton}
              alt="hanacard my reservation"
              height={40}
              style={{ borderRadius: 16 }}
            />
          </>
        ) : (
          <Menu aria-label="menu" onClick={handleMenuClick} />
        )}
      </Stack>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled("header")(
  ({ theme }) => `
    direction: ltr;
    background-color:${theme.palette.white.main};

    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;

    position: fixed;
    top: 0px;
    z-index: 100000000;
    }
`
);

const Logo = styled("img")`
  height: 55%;
`;
